import { defineMessages } from 'react-intl';

const scope = 'wage.';

export const wageMessages = defineMessages({
    wage: {
        id: scope + 'wage',
        defaultMessage: 'Wage',
    },
    regularWage: {
        id: scope + 'regularWage',
        defaultMessage: 'Regular',
    },
    nightWage: {
        id: scope + 'nightWage',
        defaultMessage: 'Night',
    },
    standbyWage: {
        id: scope + 'standbyWage',
        defaultMessage: 'Standby',
    },
    onCallActiveWage: {
        id: scope + 'onCallActiveWage',
        defaultMessage: 'Active',
    },
    onCallPassiveWage: {
        id: scope + 'onCallPassiveWage',
        defaultMessage: 'Passive',
    },
    regularRate: {
        id: scope + 'regularRate',
        defaultMessage: 'Regular rate',
    },
    nightRate: {
        id: scope + 'nightRate',
        defaultMessage: 'Night rate',
    },
    sundayRate: {
        id: scope + 'sundayRate',
        defaultMessage: 'Sunday rate',
    },
    holidayRate: {
        id: scope + 'holidayRate',
        defaultMessage: 'Holiday rate',
    },
    useMultiplicator: {
        id: scope + 'useMultiplicator',
        defaultMessage: 'Use factor',
    },
    multiplicator: {
        id: scope + 'multiplicator',
        defaultMessage: 'Factor',
    },
});
