import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { CCheckBox, CText, CTimePicker } from '../../../components';
import { TouchableView } from '../../../components/TouchableView';
import { IDayAvailability, MAvailability } from '../../../models';
import { useStyle, useTheme } from '../../../utilities/styles';
import { convertWeekAndYearToDate } from '../../../utilities/functions';
import { calendarMessages } from '../Calendar.messages';

export interface IDayAvailabilityProps {
    day: string;
    dayNumber: number;
    dayValues?: IDayAvailability;
    availability?: MAvailability;
    currentDate?: Date;
    startDate: Date;
    onChangeDay: (next?: IDayAvailability) => void;
    startHour?: number;
    noDate?: boolean;
}
/**
 * day availability row for availability edit
 */
export const DayAvailability2: FC<IDayAvailabilityProps> = ({
    day,
    dayNumber,
    dayValues,
    onChangeDay,
    availability,
    currentDate,
    startDate,
    startHour,
    noDate,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    /**
     * change day availability entry
     */
    const changeEntry = useCallback(
        (next: Partial<IDayAvailability>) =>
            onChangeDay({ ...(dayValues || {}), ...next } as IDayAvailability),
        [onChangeDay, dayValues],
    );
    /**
     * toggle active by removing entry from list or adding it
     */
    const toggleActive = useCallback(
        (setToActive: boolean) => {
            onChangeDay(
                setToActive
                    ? { from: 8, to: 16, inheriting: false, day: dayNumber }
                    : undefined,
            );
        },
        [onChangeDay, dayNumber],
    );
    /**
     * from to from self or inherited
     */
    const { from, to } = useMemo(() => {
        const s = dayValues;
        if ((!s || s.inheriting) && availability) {
            return availability;
        } else if (s) {
            return s;
        } else {
            return { from: 0, to: 24 };
        }
    }, [dayNumber, availability, dayValues]);
    /**
     * memoized date
     */
    const date = useMemo(() => {
        const start = startDate;
        const selectedDate = start.toLocaleDateString('de');

        const day = selectedDate.split('.')[0];

        return new Date(
            start.getFullYear(),
            start.getMonth(),
            +day + dayNumber - 1,
        );
    }, [startDate, dayNumber]);
    /**
     * memoize if this day is currently selected
     */
    const isSelected = useMemo(() => {
        if (!currentDate) return false;
        const selectedDate = currentDate.toLocaleDateString('de');
        const selfDate = date.toLocaleDateString('de');
        return selectedDate === selfDate;
    }, [currentDate, availability]);
    /**
     * memoized time picker readable from
     */
    const fromDate = useMemo(() => {
        const time = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            from,
        );
        return time;
    }, [startDate, from]);
    /**
     * memoized time picker readable from
     */
    const toDate = useMemo(() => {
        const time = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            to === 24 ? 23 : to,
        );
        return time;
    }, [startDate, to]);
    /**
     * set from callback
     */
    const setFrom = useCallback(
        (from: number) => changeEntry({ from: +from, inheriting: false }),
        [changeEntry],
    );
    /**
     * set to callback
     */
    const setTo = useCallback(
        (to: number) => changeEntry({ to: +to, inheriting: false }),
        [changeEntry],
    );
    /**
     * render
     */
    return (
        <View
            style={[
                {
                    borderWidth: 1,
                    borderColor: 'transparent',
                    borderRadius: 10,
                    zIndex: 1,
                },
                isSelected && { borderColor: theme.accentColor },
            ]}
        >
            <View
                style={[
                    style.horizontal,
                    style.centeredItems,
                    { paddingVertical: 10 },
                ]}
            >
                <TouchableView
                    style={[style.horizontal, style.centeredItems]}
                    onPress={() => toggleActive(!dayValues)}
                >
                    <CCheckBox
                        checked={!!dayValues}
                        onCheckedChanged={toggleActive}
                    />
                    <CText
                        style={style.leftPadded}
                        secondaryHeadline
                        message={`${
                            noDate ? '' : date.toLocaleDateString('de') + ' '
                        }${day}`}
                    />
                </TouchableView>
            </View>
            {dayValues && (
                <View
                    style={[
                        {
                            zIndex: 1,
                            borderWidth: 1,
                            borderColor: theme.borderColor,
                            borderRadius: 10,
                            backgroundColor: theme.backgroundSecondaryColor,
                            marginVertical: 10,
                        },
                    ]}
                >
                    <View
                        style={[
                            style.horizontalSplit,
                            style.horizontalHeavyPadded,
                            style.centeredItems,
                            { zIndex: 2 },
                        ]}
                    >
                        <CText message={calendarMessages.from} />
                        <CTimePicker
                            value={fromDate}
                            onChange={(next) => setFrom(next.getHours())}
                            timeIntervals={60}
                        />
                    </View>
                    <View style={style.thinSeparator} />
                    <View
                        style={[
                            style.horizontalSplit,
                            style.horizontalHeavyPadded,
                            style.centeredItems,
                            { zIndex: 1 },
                        ]}
                    >
                        <CText message={calendarMessages.to} />
                        <CTimePicker
                            value={toDate}
                            onChange={(next) => setTo(next.getHours())}
                            timeIntervals={60}
                        />
                    </View>
                </View>
            )}
        </View>
    );
};
