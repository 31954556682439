import React, { FC, useCallback, useMemo } from 'react';
import { CTimePicker } from '../../../components';
import { convertWeekAndYearToDate } from '../../../utilities/functions';

export const DayInput: FC<{
    week: number;
    year: number;
    day: {
        day: number;
        from: number;
        to: number;
    };
    onChangeDay: (
        next: Partial<{
            day: number;
            from: number;
            to: number;
        }>,
    ) => void;
}> = ({ year, week, day, onChangeDay }) => {
    /**
     * return base date
     */
    const baseDate = useMemo(
        () => new Date(convertWeekAndYearToDate(year, week)),
        [year, week],
    );
    /**
     * memoized time picker readable from
     */
    const fromDate = useMemo(() => {
        const time = new Date(
            baseDate.getFullYear(),
            baseDate.getMonth(),
            baseDate.getDate(),
            day.from,
        );
        return time;
    }, [baseDate, day]);
    /**
     * memoized time picker readable from
     */
    const toDate = useMemo(() => {
        const time = new Date(
            baseDate.getFullYear(),
            baseDate.getMonth(),
            baseDate.getDate(),
            day.to === 24 ? 23 : day.to,
        );
        return time;
    }, [baseDate, day]);
    /**
     * set from callback
     */
    const setFrom = useCallback(
        (from: number) => onChangeDay({ from: +from }),
        [onChangeDay],
    );
    /**
     * set to callback
     */
    const setTo = useCallback(
        (to: number) => onChangeDay({ to: +to }),
        [onChangeDay],
    );

    return (
        <>
            <CTimePicker
                value={fromDate}
                onChange={(next) => setFrom(next.getHours())}
                timeIntervals={60}
            />
            <CTimePicker
                value={toDate}
                onChange={(next) => setTo(next.getHours())}
                timeIntervals={60}
            />
        </>
    );
};
