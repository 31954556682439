import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCard, CIcon, CText, Spinner } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { filenameMessages } from '../../../utilities/messages';
import { useSecureNavigate } from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ChatView } from '../../Chat';
import { ContractOverviewBar } from '../components/ContractOverviewBar';
import { ContractTitle } from '../components/ContractTitle';
import { SelectSigningEmployer } from '../components/SelectSigningEmployer';
import { contractMessages } from '../contract.messages';
import { ContractFiles } from './components/ContractFiles';
import { useContractViewState } from './useContractViewState';
import { EApplicationType, EContractStatus, EUserType } from '../../../enums';
import { isLocalhost } from '../../../utilities/constants';
import { ScrollProvider } from '../../../utilities/contexts';
import { useDimensions } from '../../../utilities/hooks';
import { BasicContractView } from './BasicContractView';
import { JobOverviewBar } from '../components/JobOverviewBar';
import { ApplicantOverviewBar } from '../components/ApplicantOverviewBar';
import { useFireBase } from '../../../utilities/firebase';
import { jobMessages } from '../../Job/job.messages';
import { TimesheetSettings } from './components/TimesheetSettings';
import { WorkplaceSettings } from './components/WorkplaceSettings';
import { isEmployer, isPeasant, isSuperUser } from '../../../utilities/auth';
import { ContractExtensionButton } from './components/ContractExtensionButton';
/**
 * enum to declare which overview to show
 */
enum EContractViewModes {
    contractDetails = 'contractDetails',
    employeeDetails = 'employeeDetails',
    jobDetails = 'jobDetails',
    timesheet = 'timesheet',
    workplace = 'workplace',
}
/**
 * Contract view
 * - overview over contract values
 * - all documents related to contract
 * - options to upload documents and request a signature
 * - display the chat related to this contract
 * @returns
 */
export const ContractView: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const {
        contract,
        job,
        application,
        chat,
        request,
        timesheetComplete,
        openReview,
        handleSigningEmployerChange,
        preFile,
        handleFile,
        joinChat,
        setContract,
        setReload,
        reload,
        hpw,
        reviewAndComplete,
    } = useContractViewState();
    const { userData } = useFireBase();
    const { mainHeight } = useDimensions();
    const [containerHeight, setContainerHeight] = useState(0);
    const [mode, setMode] = useState<EContractViewModes>();
    /**
     * return spinner if no contract
     */
    if (!contract) {
        return <Spinner />;
    }
    /**
     * return basic view if type is basic
     */
    if (contract.type === EApplicationType.default) {
        return (
            <BasicContractView
                {...{
                    request,
                    contract,
                    job,
                    application,
                    chat,
                    timesheetComplete,
                    openReview,
                    handleSigningEmployerChange,
                    preFile,
                    handleFile,
                    joinChat,
                    setContract,
                    setReload,
                    reload,
                    hpw,
                    reviewAndComplete,
                }}
            />
        );
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View
                nativeID="minHeightContainer"
                style={{
                    minHeight: mainHeight - 40,
                }}
            >
                <CCard>
                    <View
                        style={[style.horizontal, style.centeredItems]}
                        {...{
                            dataSet: {
                                cy: 'contract-view-headline-container',
                            },
                        }}
                    >
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <View>
                            <ContractTitle
                                contract={contract}
                                horizontalPadded
                            />
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CIcon
                                    icon="calendar"
                                    tint={theme.textSecondaryColor}
                                    size={14}
                                />
                                <CText message={jobMessages.fromTo} />
                                <CText style={style.leftPadded}>
                                    {`${new Date(
                                        contract.from,
                                    ).toLocaleDateString('de')} - ${new Date(
                                        contract.to,
                                    ).toLocaleDateString('de')}`}
                                </CText>
                                {hpw > 0 && (
                                    <CText style={style.leftPadded}>
                                        {`| ${hpw} ${format(
                                            contractMessages.hoursPerWeekUnit,
                                        )}`}
                                    </CText>
                                )}
                            </View>
                        </View>
                    </View>
                    <View style={style.paddedThinSeparator} />
                    {userData.type !== EUserType.talent && (
                        <View
                            style={style.horizontalWrap}
                            nativeID="detail-buttons"
                        >
                            {Object.values(EContractViewModes).map(
                                (thisMode) =>
                                    (thisMode !==
                                        EContractViewModes.jobDetails ||
                                        contract.jobId) &&
                                    (thisMode !==
                                        EContractViewModes.workplace ||
                                        isSuperUser(userData) ||
                                        isEmployer(userData)) &&
                                    (thisMode !==
                                        EContractViewModes.timesheet ||
                                        isSuperUser(userData) ||
                                        isPeasant(userData)) && (
                                        <CButton
                                            key={thisMode}
                                            title={contractMessages[thisMode]}
                                            icon={
                                                thisMode !== 'timesheet'
                                                    ? undefined
                                                    : 'clipboardAccount'
                                            }
                                            onPress={() => {
                                                setMode(
                                                    mode !== thisMode
                                                        ? thisMode
                                                        : undefined,
                                                );
                                            }}
                                            small
                                            style={style.smallMargin}
                                            minor={mode !== thisMode}
                                        />
                                    ),
                            )}
                            {contract.status !== EContractStatus.completed && (
                                <ContractExtensionButton
                                    contract={contract}
                                    issueReload={() => setReload(Date.now())}
                                />
                            )}
                            {(isLocalhost() ||
                                contract.status ===
                                    EContractStatus.completed) && (
                                <CButton
                                    title={contractMessages.provideReview}
                                    onPress={openReview}
                                    icon={'star'}
                                    small
                                    minor
                                    style={style.smallMargin}
                                />
                            )}
                        </View>
                    )}
                </CCard>
                {mode === EContractViewModes.contractDetails && (
                    <ContractOverviewBar contract={contract} />
                )}
                {mode === EContractViewModes.jobDetails && job && (
                    <JobOverviewBar job={job} />
                )}
                {mode === EContractViewModes.employeeDetails &&
                    (application || contract.employee) && (
                        <ApplicantOverviewBar
                            request={request}
                            application={application}
                            applicant={contract.employee}
                        />
                    )}
                {/* {mode === EContractViewModes.settings && (
                    <ContractSettings
                        contract={contract}
                        issueReload={() => setReload(Date.now())}
                    />
                )} */}
                {mode === EContractViewModes.timesheet && (
                    <TimesheetSettings
                        contract={contract}
                        issueReload={() => setReload(Date.now())}
                    />
                )}
                {mode === EContractViewModes.workplace && (
                    <WorkplaceSettings
                        contract={contract}
                        issueReload={() => setReload(Date.now())}
                    />
                )}
                <SelectSigningEmployer
                    contract={contract}
                    onChange={handleSigningEmployerChange}
                />
                {userData.type !== EUserType.talent &&
                    contract.to > Date.now() && (
                        <View style={style.card}>
                            <CText message={contractMessages.mainInfoText} />
                            {[
                                filenameMessages.leasingContract,
                                filenameMessages.equalPayDeclaration,
                            ].map((m, i) => (
                                <CText
                                    key={m.id}
                                    message={`${i + 1}. ${format(m)}`}
                                />
                            ))}
                            <View style={style.paddedThinSeparator} />
                            <CText
                                message={contractMessages.secondaryInfoText}
                            />
                            {[
                                filenameMessages.houseRules,
                                filenameMessages.locationPlan,
                                filenameMessages.contactDetails,
                                filenameMessages.educationCertificate,
                                filenameMessages.driversLicense,
                                filenameMessages.timesheetTemplate,
                            ].map((m, i) => (
                                <CText
                                    key={m.id}
                                    message={`${i + 1}. ${format(m)}`}
                                />
                            ))}
                        </View>
                    )}
                {contract.to <= Date.now() && (
                    <CCard>
                        <CText message={contractMessages.contractExpired} />
                        <CButton
                            onPress={reviewAndComplete}
                            title={contractMessages.completeContract}
                        />
                    </CCard>
                )}
                <View
                    style={[style.horizontal, style.flex1]}
                    onLayout={(e) =>
                        setContainerHeight(e.nativeEvent.layout.height)
                    }
                >
                    <View style={[style.flex1]}>
                        <View
                            style={{
                                minHeight: containerHeight - 20,
                            }}
                            nativeID="contract-documents"
                        >
                            <ContractFiles
                                contract={contract}
                                maxHeight={containerHeight - 20}
                            />
                        </View>
                    </View>
                    {!!chat ? (
                        <View
                            style={[
                                style.card,
                                style.flex1,
                                { height: '100%' },
                            ]}
                            nativeID="contract-chat"
                        >
                            <ChatView
                                chat={chat}
                                handleFile={handleFile}
                                preFile={preFile}
                                embedded
                            />
                        </View>
                    ) : (
                        <View
                            style={[style.card, style.flex1]}
                            nativeID="contract-chat"
                        >
                            <CText
                                message={
                                    contractMessages.youAreNotParticipatingInThisChat
                                }
                                centered
                            />
                            <View
                                style={[
                                    style.verticalPadded,
                                    style.horizontalSpaced,
                                ]}
                            >
                                <CButton
                                    onPress={joinChat}
                                    title={contractMessages.joinChat}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </ScrollProvider>
    );
};
