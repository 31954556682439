import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, CText, Spinner } from '../../components';
import { CImage } from '../../components/elements/CImage';
import { ECollections, EUserType } from '../../enums';
import { MNotification } from '../../models/MNotification';
import { ScrollProvider } from '../../utilities/contexts/Scroll';
import { useFireBase } from '../../utilities/firebase';
import { IFilter, IInequality } from '../../utilities/firebase/store';
import { useFormat } from '../../utilities/intl';
import { actionMessages } from '../../utilities/messages';
import { generalMessages } from '../../utilities/messages/general.messages';
import { useStyle } from '../../utilities/styles';
import { NotificationRow } from './components/NotificationRow';
import { MAgency, MWorkplace } from '../../models';

enum EModes {
    recent = 'recent',
    archived = 'archived',
    pinned = 'pinned',
    public = 'public',
}

export const Notifications: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const { getDataIndex, userData, put, userAgencies, userWorkplaces } =
        useFireBase();
    const [notifications, setNotifications] = useState<MNotification[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const [mode, setMode] = useState<EModes>();
    /**
     * callback to load notifications
     */
    const loadMore = useCallback(
        (offset?: MNotification) => {
            if (!userData.documentId) {
                return;
            }
            const fors = ['null'];
            if (mode !== EModes.public) {
                fors.push(`user_${userData.documentId}`);
                fors.push(`group_${userData.type}`);
                userWorkplaces.forEach((w) => fors.push(`wp_${w.documentId}`));
                userAgencies.forEach((a) => fors.push(`a_${a.documentId}`));
            }
            const params = {
                orderBy: 'createdOn',
                filter: [
                    {
                        field: 'for',
                        value: fors,
                        operator: 'in',
                    },
                ] as IFilter[],
                inequalities: [] as IInequality[],
            };
            if (mode === EModes.pinned) {
                params.filter.push({ field: 'pinned', value: true });
            }
            getDataIndex(ECollections.notifications, {
                ...params,
                limit: 10,
                startDocumentId: offset ? offset.documentId : undefined,
            }).then((result) => {
                const next = (result as MNotification[]).map(
                    (r) => new MNotification(r),
                );
                if (!offset) {
                    setNotifications(next);
                    setLoading(false);
                } else {
                    setNotifications((prev) => [...prev, ...next]);
                    setLoadingMore(false);
                }
            });
        },
        [userData, mode, userWorkplaces, userAgencies],
    );
    /**
     * effect to trigger initial load on changed callback
     */
    useEffect(() => {
        setLoading(true);
        loadMore();
    }, [loadMore]);
    /**
     * callback to mark notifications as read
     */
    const markAllAsRead = useCallback(() => {
        setNotifications(
            notifications.map((n, i) => {
                if (!n.readBy.find((r) => r.uid === userData.documentId)) {
                    const next = new MNotification(n);
                    next.readBy = [...next.readBy];
                    next.readBy.push({
                        uid: userData.documentId,
                        on: Date.now(),
                    });
                    put(ECollections.notifications, n.documentId, next);
                    return next;
                }
                return n;
            }),
        );
    }, [notifications]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <View
                    style={[
                        style.horizontalSplit,
                        style.centeredItems,
                        style.horizontalWrap,
                    ]}
                >
                    <CText bold headline>
                        {format(generalMessages.notifications)}
                    </CText>
                    <CButton
                        title={actionMessages.markAllAsRead}
                        onPress={markAllAsRead}
                    />
                </View>
                {/* <View style={style.horizontalWrap}>
                    <CButton
                        title={format(generalMessages.recent)}
                        onPress={() => setMode(EModes.recent)}
                        minor={mode !== EModes.recent}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={format(generalMessages.archived)}
                        onPress={() => setMode(EModes.archived)}
                        minor={mode !== EModes.archived}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={format(generalMessages.pinned)}
                        onPress={() => setMode(EModes.pinned)}
                        minor={mode !== EModes.pinned}
                        small
                        style={style.horizontalPadded}
                    />
                    <CButton
                        title={format(generalMessages.public)}
                        onPress={() => setMode(EModes.public)}
                        minor={mode !== EModes.public}
                        small
                        style={style.horizontalPadded}
                    />
                </View> */}
            </View>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {notifications.map((notification) => (
                        <NotificationRow
                            key={notification.documentId}
                            notification={notification}
                            onChange={(next) => {
                                const index = notifications.findIndex(
                                    (note) =>
                                        note.documentId === next.documentId,
                                );
                                if (index >= 0) {
                                    notifications[index] = next;
                                    setNotifications([...notifications]);
                                }
                            }}
                        />
                    ))}
                    {loadingMore ? (
                        <Spinner />
                    ) : (
                        !!notifications.length &&
                        notifications.length % 10 === 0 && (
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CButton
                                    onPress={() => {
                                        setLoadingMore(true);
                                        loadMore(
                                            notifications[
                                                notifications.length - 1
                                            ],
                                        );
                                    }}
                                    minor
                                    title={format(actionMessages.loadMore)}
                                />
                            </View>
                        )
                    )}
                    {!notifications.length && (
                        <View style={style.card}>
                            <CImage image="notifications" />
                            <View
                                style={[
                                    style.horizontalSpaced,
                                    style.verticalPadded,
                                ]}
                            >
                                <CText
                                    bold
                                    centered
                                    headline
                                    message={format(generalMessages.noResults)}
                                />
                            </View>
                        </View>
                    )}
                </>
            )}
        </ScrollProvider>
    );
};
