import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { IDayAvailability } from '../../../../models';
import { TouchableView, CCheckBox, CText } from '../../../../components';
import { calendarMessages } from '../../../Calendar/Calendar.messages';
import { DayAvailability2 } from '../../../Calendar/components/DayAvailability.v2';
import { useStyle } from '../../../../utilities/styles';
import { AllDayAvailabilityOnDays } from '../../../Calendar/components/AllDayAvailabilityOnDays';
import { dayMessages } from '../../../../utilities/messages';
import { useFormat } from '../../../../utilities/intl';

export const WorkingTimes: FC<{
    days: IDayAvailability[];
    onChangeDays: (next: IDayAvailability[]) => void;
    date: Date;
}> = ({ days, onChangeDays, date }) => {
    const style = useStyle();
    const format = useFormat();
    /**
     * days translated
     */
    const weekDays = useMemo(() => {
        return Object.keys(dayMessages)
            .filter((v) => !v.includes('Short'))
            .map((key) => format(dayMessages[key as keyof typeof dayMessages]));
    }, []);
    /**
     * callback to change day value
     */
    const handleDayChange = useCallback(
        (dayNumber: number, day?: IDayAvailability) => {
            const prevIndex = days.findIndex((d) => d.day === dayNumber);
            const next = [...days];
            if (prevIndex >= 0) {
                if (day) {
                    next.splice(prevIndex, 1, day);
                } else {
                    next.splice(prevIndex, 1);
                }
            } else if (day) {
                next.push(day);
            }
            onChangeDays(next);
        },
        [days],
    );
    /**
     * callback to set everyday to inheriting
     */
    const setInheriting = useCallback(
        (excludeWeekend = false) =>
            onChangeDays(
                weekDays
                    .filter((_, i) => !excludeWeekend || i < 5)
                    .map((_, i) => {
                        const prevDay = days.find((d) => d.day === i + 1);
                        return prevDay
                            ? { ...prevDay, inheriting: true }
                            : {
                                  day: i + 1,
                                  inheriting: true,
                                  from: 8,
                                  to: 16,
                              };
                    }),
            ),
        [weekDays],
    );
    /**
     * render
     */
    return (
        <View>
            {days.length && !days.find((d) => !d.inheriting) ? (
                <View style={{ zIndex: 1 }}>
                    <AllDayAvailabilityOnDays
                        days={days}
                        onChange={onChangeDays}
                        excludeWeekend={days.length === 5}
                        startDate={date}
                    />
                </View>
            ) : (
                <View style={{ zIndex: 1 }}>
                    <TouchableView
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            { paddingVertical: 10 },
                        ]}
                        onPress={() => setInheriting()}
                    >
                        <CCheckBox
                            checked={false}
                            onCheckedChanged={() => setInheriting()}
                        />
                        <CText
                            secondaryHeadline
                            style={style.leftPadded}
                            message={calendarMessages.everyDay}
                        />
                    </TouchableView>
                    <TouchableView
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            { paddingVertical: 10 },
                        ]}
                        onPress={() => setInheriting(true)}
                    >
                        <CCheckBox
                            checked={false}
                            onCheckedChanged={() => setInheriting(true)}
                        />
                        <CText
                            secondaryHeadline
                            style={style.leftPadded}
                            message={calendarMessages.everyWeekDay}
                        />
                    </TouchableView>
                    {weekDays.map((day, i) => {
                        const dayValues = days.find((d) => d.day === i + 1);
                        return (
                            <View
                                style={{ zIndex: days.length + 1 - i }}
                                key={day}
                            >
                                <DayAvailability2
                                    day={day}
                                    dayNumber={i + 1}
                                    dayValues={dayValues}
                                    onChangeDay={(day) =>
                                        handleDayChange(i + 1, day)
                                    }
                                    startDate={date}
                                    noDate
                                />
                            </View>
                        );
                    })}
                </View>
            )}
        </View>
    );
};
