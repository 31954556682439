import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { ScrollProvider } from '../../../utilities/contexts';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CButton, CCard, CIcon, CText } from '../../../components';
import { timesheetMessages } from '../timesheet.messages';
import { useParams, useSecureNavigate } from '../../../utilities/routing';
import { MContract, MTimesheet } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { ECollections, ETimesheetType } from '../../../enums';
import { generalMessages, monthMessages } from '../../../utilities/messages';
import { TimesheetRow } from './components/TimesheetRow';

export const TimesheetList: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { secureNavigate } = useSecureNavigate();
    const { getDataById, getDataIndex } = useFireBase();
    const { contractId } = useParams();
    const [sheets, setSheets] = useState<MTimesheet[]>([]);
    const [contract, setContract] = useState<MContract>();
    /**
     * effect to load the contract the time sheet is for
     */
    useEffect(() => {
        if (!contractId) return;

        getDataById(ECollections.contracts, contractId).then((c) =>
            setContract(new MContract(c)),
        );
    }, [contractId]);
    /**
     * effect to load all data related to contract after contract did load
     */
    useEffect(() => {
        if (!contract) return;
        const timesheetCollection = `${ECollections.contracts}/${contract.documentId}/${ECollections.timesheets}`;
        getDataIndex(timesheetCollection).then((ts) => {
            const results = (ts as any[]).map((t) => new MTimesheet(t));

            setSheets(results);
        });
    }, [contract, contractId]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <CCard style={[style.horizontalSplit, style.centeredItems]}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CButton
                        cy={'back'}
                        onPress={async () => {
                            secureNavigate(-1);
                        }}
                        icon={'chevronLeft'}
                        small
                    />
                    <CText
                        headline
                        style={style.leftPadded}
                        message={timesheetMessages.timesheetList}
                    />
                </View>
                <CButton
                    cy={'new-timesheet'}
                    onPress={async () => {
                        secureNavigate(`/contract/${contractId}/timesheet`);
                    }}
                    icon={'plus'}
                    small
                />
            </CCard>
            {sheets.map((sheet) => (
                <TimesheetRow key={sheet.documentId} sheet={sheet} />
            ))}
        </ScrollProvider>
    );
};
