import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { MAvailability } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { ECollections, EField, EProfession } from '../../../enums';
import { IFilter } from '../../../utilities/firebase/store';
import { ScrollProvider, useEnvironment } from '../../../utilities/contexts';
import { useStyle } from '../../../utilities/styles';
import {
    CCard,
    CImage,
    CPicker,
    CText,
    EducationPicker,
    FieldPicker,
    ProfessionPicker,
} from '../../../components';
import { generalMessages, monthMessages } from '../../../utilities/messages';
import { calendarMessages } from '../../Calendar/Calendar.messages';
import { isSuperUser } from '../../../utilities/auth';
import { AvailableUserRow } from './components/AvailableUserRow';
import { useFormat } from '../../../utilities/intl';
import { availabilityMessages } from '../availability.messages';

export const AvailableUsersList: FC = () => {
    const { getDataIndex, userData } = useFireBase();
    const style = useStyle();
    const { region } = useEnvironment();
    const format = useFormat();
    // local state
    const [availabilities, setAvailabilities] = useState<MAvailability[]>([]);
    const [unavailabilities, setUnavailabilities] = useState<MAvailability[]>(
        [],
    );
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    const [field, setField] = useState<EField>();
    const [profession, setProfession] = useState<EProfession>();
    const [education, setEducation] = useState<string>();

    const months = useMemo(() => {
        const thisMonth = new Date().getMonth();
        const thisYear = new Date().getFullYear();

        const monthKeys = Object.keys(monthMessages).filter(
            (m) => !m.includes('Short'),
        );

        const moi = [];

        for (let i = thisMonth; i <= thisMonth + 12; i++) {
            const yearOffset = Math.floor(i / 11);
            moi.push({
                label: `${format(
                    monthMessages[
                        monthKeys[i % 12] as keyof typeof monthMessages
                    ],
                )} ${thisYear + yearOffset}`,
                value: i + 1,
            });
        }

        return moi;
    }, []);
    /**
     * effect to load availabilities
     */
    useEffect(() => {
        const filter: IFilter[] = [{ field: 'deleted', value: false }];

        if (month) {
            filter.push({
                field: Object.keys(monthMessages).filter(
                    (m) => !m.includes('Short'),
                )[month % 12],
                value: true,
            });
        }
        if (year) {
            filter.push({
                field: 'activeYears.y' + year,
                value: true,
            });
        }
        if (education) {
            filter.push({
                field: 'educations',
                operator: 'array-contains',
                value: education,
            });
        } else if (profession) {
            filter.push({
                field: 'profession',
                value: profession,
            });
        } else if (field) {
            filter.push({
                field: 'field',
                value: field,
            });
        }
        if (!isSuperUser(userData)) {
            filter.push({ field: 'region', value: region });
        }
        getDataIndex(ECollections.availabilities, { filter }).then((result) => {
            const next = (result as MAvailability[]).map(
                (r) => new MAvailability(r),
            );
            setAvailabilities(next.filter((a) => !a.negate));
            setUnavailabilities(next.filter((a) => a.negate));
        });
    }, [month, year, field, profession, education, region, userData]);

    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.card]}>
                <CText
                    style={style.verticalPadded}
                    bold
                    headline
                    message={generalMessages.users}
                />
                <View style={style.horizontalWrap}>
                    <CPicker
                        title={calendarMessages.month}
                        value={month + 1}
                        values={months}
                        onChange={(v) => {
                            setMonth(v - 1);
                            setYear(
                                Number(
                                    months
                                        .find(({ value }) => v === value)
                                        ?.label.split(' ')[1],
                                ),
                            );
                        }}
                        horizontal
                    />
                    <FieldPicker
                        onChange={(f) => {
                            setField(f);
                            setProfession(undefined);
                            setEducation(undefined);
                        }}
                        value={field}
                        horizontal
                        allowEmpty
                    />
                    {field && (
                        <ProfessionPicker
                            onChange={(p) => {
                                setProfession(p);
                                setEducation(undefined);
                            }}
                            value={profession}
                            field={field}
                            horizontal
                            allowEmpty
                        />
                    )}
                    {profession && (
                        <EducationPicker
                            onChange={(e) => setEducation(e as string)}
                            value={education}
                            profession={profession}
                            field={field}
                            horizontal
                            allowEmpty
                        />
                    )}
                </View>
            </View>
            {availabilities.map((a) => (
                <AvailableUserRow
                    availability={a}
                    key={a.documentId}
                    unavailabilities={unavailabilities}
                />
            ))}
            {!availabilities.length && (
                <CCard>
                    <CImage image={'search'} />
                    <View
                        style={[style.horizontalSpaced, style.verticalPadded]}
                    >
                        <CText
                            bold
                            centered
                            headline
                            message={availabilityMessages.noAvailableUsers}
                        />
                    </View>
                </CCard>
            )}
        </ScrollProvider>
    );
};
