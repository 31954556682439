import React, { FC, useCallback, useMemo, useState } from 'react';
import {
    MContract,
    MContractFile,
    MFile,
    MSignaturePosition,
} from '../../../../models';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { Linking, View } from 'react-native';
import { CIcon, CButton, CText, TouchableView } from '../../../../components';
import { useFireBase } from '../../../../utilities/firebase';
import { EUserType } from '../../../../enums';
import { isPeasant, isEmployer } from '../../../../utilities/auth';
import { useShowCourierDialog } from './functions/useShowCourierDialog';
import { useFormat } from '../../../../utilities/intl';
import {
    actionMessages,
    filenameMessages,
    generalMessages,
} from '../../../../utilities/messages';
import { contractMessages } from '../../contract.messages';
import { useDialog } from '../../../../utilities/dialog';
import { useLock } from '../../../../utilities/hooks';
import { useNavigate } from '../../../../utilities/routing';
import { getRegionUrl } from '../../../../utilities/constants';
import { useEnvironment } from '../../../../utilities/contexts';
import { ContractFile } from './ContractFile';
import { IDialogButton } from '../../../../utilities/dialog/IDialog';

export const Envelope: FC<{
    envelope: { envelopeId: string; files: MFile[] };
    contract: MContract;
    issueReload: () => void;
}> = ({ envelope, contract, issueReload }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const { lock } = useLock();
    const { userData, callFunction, getFileDownloadUrl } = useFireBase();
    const { environment, region } = useEnvironment();
    const navigate = useNavigate();
    const dialog = useDialog();
    const format = useFormat();
    const [open, setOpen] = useState(false);
    /**
     * memoized check if this is a namirial envelope or courier
     * - namirial envelopes have porper string ids from namirial
     * - courier envelopes only have Date.now ids
     */
    const isNamirial = useMemo(
        () =>
            isNaN(+envelope.envelopeId) &&
            envelope.files.find((v) => v.namirialFileId),
        [envelope],
    );
    /**
     * check if is envelope with partial signing
     */
    const isEnvelope = useMemo(
        () =>
            envelope.files.find(
                (v) =>
                    v.envelopeType === 'unqualified' ||
                    v.envelopeType === 'namirial',
            ),
        [envelope],
    );
    /**
     * check if it is a courier envelope
     */
    const isCourier = useMemo(() => !isEnvelope, [isEnvelope]);
    /**
     * my Signature positions
     */
    const myPositions = useMemo(
        () =>
            envelope.files.reduce((acc, f) => {
                const next = f.signaturePositions.filter((sp) =>
                    sp.userId
                        ? userData.documentId === sp.userId
                        : isPeasant(userData)
                        ? sp.type === EUserType.user
                        : isEmployer(userData)
                        ? sp.type === EUserType.employer
                        : false,
                );
                acc.push(...next);
                return acc;
            }, [] as MSignaturePosition[]),
        [envelope],
    );
    /**
     * memoized check for namirial if you signed
     */
    const singed = useMemo(() => {
        const unsignedPositions = myPositions.filter((p) => !p.signed);
        return !unsignedPositions.length;
    }, [myPositions]);
    /**
     * memoized check for namirial everything is signed
     */
    const completelySinged = useMemo(() => {
        const unsignedPositions = envelope.files
            .reduce((acc, f) => {
                const next = f.signaturePositions;
                acc.push(...next);
                return acc;
            }, [] as MSignaturePosition[])
            .filter((p) => !p.signed);

        return !unsignedPositions.length;
    }, [envelope]);
    /**
     * url to signature position for unqualified && namirial
     */
    const mySignaturePositionUrl = useMemo(() => {
        if (myPositions.length) {
            return myPositions[0].url;
        }
    }, [myPositions]);
    /**
     * url to courier tracking
     */
    const trackingUrl = useMemo(() => {
        const fileWithTracking = envelope.files.find(
            (f) => f.signatureRequest?.trackingUrl,
        );

        return fileWithTracking?.signatureRequest?.trackingUrl;
    }, [envelope]);
    /**
     * timestamp of url tracking
     */
    const trackinUrlOn = useMemo(() => {
        const fileWithTracking = envelope.files.find(
            (f) => f.signatureRequest?.trackingUrlOn,
        );

        return fileWithTracking?.signatureRequest?.trackingUrlOn;
    }, [envelope]);
    /**
     * memoized check if this user has to sign
     */
    const hasToSign = useMemo(() => {
        const namirialUnsigned = myPositions.length && !singed;
        const courierNotAuthorNoTracking =
            !trackingUrl &&
            userData.documentId !== envelope.files[0].author &&
            envelope.files[0].envelopeType === 'courier';
        const next = namirialUnsigned || courierNotAuthorNoTracking;

        return next;
    }, [myPositions, singed, userData, envelope]);
    /**
     * check if envelope is deletable
     */
    const canDeleteEnvelope = useMemo(() => {
        return !(
            envelope.files.find((f) => userData.documentId !== f.author) ||
            envelope.files.find((f) => f.envelopeType === 'namirial') ||
            envelope.files.find((f) => f.signatureRequest?.trackingUrl) ||
            envelope.files.find((f) => f.signedBy.length) ||
            envelope.files.find((f) =>
                f.signaturePositions.find((sp) => sp.signed),
            )
        );
    }, [envelope]);
    /**
     * callback to follow tracking url
     */
    const followTrackingUrl = useCallback(() => {
        if (!trackingUrl) return;
        Linking.openURL(
            trackingUrl.includes('https') || trackingUrl.includes('http')
                ? trackingUrl
                : 'https://' + trackingUrl,
        );
    }, [trackingUrl]);
    /**
     * callback to open info dialog where files can be downloaded
     */
    const showInfo = useCallback(async () => {
        const subMessages = [
            envelope.files
                .map(
                    (efi) =>
                        format(filenameMessages[efi.type]) + ' - ' + efi.name,
                )
                .join('\n'),
        ];
        if (trackingUrl) {
            let trackingUrlString = `${format(
                contractMessages.tracking,
            )}: ${trackingUrl}`;
            if (trackinUrlOn) {
                trackingUrlString += `\n${format(
                    contractMessages.trackingUrlOn,
                )} ${new Date(trackinUrlOn).toLocaleDateString('de')}`;
            }
            subMessages.push(trackingUrlString);
        }
        const buttons: IDialogButton[] = [
            {
                text: contractMessages.downloadEnvelope,
                onPress: async () => {
                    const unlock = lock();
                    const path = await callFunction('getEnvelopeZip', {
                        contractId: contract.documentId,
                        fileId: envelope.files[0].documentId,
                    });
                    const url = await getFileDownloadUrl(path);
                    await Linking.openURL(url);
                    unlock();
                },
                icon: 'download',
            },
        ];
        if (trackingUrl) {
            buttons.unshift({
                text: contractMessages.tracking,
                onPress: followTrackingUrl,
                icon: 'share',
            });
        }
        await dialog({
            icon: isCourier ? 'plane' : 'info',
            title: isCourier
                ? contractMessages.courierEnvelopeTitle
                : contractMessages.envelopeTitle,
            message: isCourier
                ? trackingUrl
                    ? contractMessages.courierEnvelopeUnderwayText
                    : contractMessages.courierEnvelopeText
                : contractMessages.envelopeText,
            subMessages,
            buttons,
            verticalButtons: true,
            cancelButton: {
                text: actionMessages.close,
                color: theme.warningColor,
            },
        });
    }, [
        contract,
        envelope,
        followTrackingUrl,
        trackinUrlOn,
        trackingUrl,
        isCourier,
    ]);
    /**
     * callback to show courier dialog request
     */
    const showCourierDialog = useShowCourierDialog(issueReload);
    /**
     * callback to show signature request
     */
    const showSignatureRequest = useCallback(() => {
        if (mySignaturePositionUrl) {
            const url = getRegionUrl(region, environment);
            if (mySignaturePositionUrl.includes(url)) {
                const next = mySignaturePositionUrl
                    .replace(url, '')
                    .replace('https://', '')
                    .replace('http://', '');
                navigate(next);
            } else {
                Linking.openURL(mySignaturePositionUrl);
            }
        }
        showCourierDialog(envelope.files[0], contract);
    }, [
        showCourierDialog,
        contract,
        envelope,
        mySignaturePositionUrl,
        environment,
        region,
    ]);
    /**
     * callback to remove envelope
     * TODO: dialog
     */
    const removeEnvelope = useCallback(async () => {
        if (canDeleteEnvelope) {
            if (
                await dialog({
                    icon: 'question',
                    title: contractMessages.deleteEnvelope,
                    message: contractMessages.deleteEnvelopeText,
                    buttons: [
                        {
                            text: actionMessages.delete,
                            color: theme.errorColor,
                        },
                    ],
                    cancelButton: { text: actionMessages.cancel },
                })
            ) {
                const unlock = lock();
                await callFunction('removeUnusedEnvelope', {
                    envelopeId: envelope.envelopeId,
                    contractId: contract.documentId,
                });
                unlock();
                issueReload();
            }
        }
    }, [envelope, issueReload, theme]);
    /**
     * render
     */
    return (
        <>
            <View
                style={[
                    { marginVertical: 5, padding: 5, borderRadius: 5 },
                    hasToSign && style.accentBorder,
                ]}
            >
                <TouchableView
                    onPress={() => setOpen(!open)}
                    style={[style.horizontalSplit, style.centeredItems]}
                >
                    <View style={[style.horizontal, style.centeredItems]}>
                        <View>
                            <CIcon
                                icon={isNamirial ? 'namirial' : 'plane'}
                                tint={hasToSign ? theme.accentColor : undefined}
                            />
                            <CIcon
                                icon={open ? 'chevronDown' : 'chevronRight'}
                            />
                        </View>
                        <View style={style.leftPadded}>
                            <CText
                                message={
                                    isCourier
                                        ? contractMessages.courierEnvelopeTitle
                                        : generalMessages.envelope
                                }
                                secondaryHeadline
                            />
                            <View style={style.horizontal}>
                                {envelope.files.length > 1 && (
                                    <CText
                                        message={format(
                                            contractMessages.xFiles,
                                            {
                                                x: envelope.files.length,
                                            },
                                        )}
                                    />
                                )}
                                {envelope.files.length === 1 && (
                                    <CText message={envelope.files[0].name} />
                                )}
                            </View>
                        </View>
                    </View>
                    <View style={style.horizontal}>
                        {/* SINGLE CHECK IF ENVELOPE IS SIGNED BY THIS USER */}
                        {isEnvelope && singed && !completelySinged && (
                            <View
                                style={[
                                    style.centeredItems,
                                    style.centeredContent,
                                    { marginHorizontal: 25, width: 0 },
                                ]}
                            >
                                <CIcon icon="check" />
                            </View>
                        )}
                        {/* SHOW DOUBLE CHECK IF ENVELOPE IS SIGNED */}
                        {isEnvelope && completelySinged && (
                            <View
                                style={[
                                    style.centeredItems,
                                    style.centeredContent,
                                    { marginHorizontal: 25, width: 0 },
                                ]}
                            >
                                <CIcon
                                    icon="checkDouble"
                                    tint={theme.successColor}
                                />
                            </View>
                        )}
                        {canDeleteEnvelope && (
                            <CButton
                                icon="minus"
                                iconColor={theme.errorColor}
                                fontStyles={{ color: theme.errorColor }}
                                minor
                                transparent
                                onPress={removeEnvelope}
                                style={{
                                    marginHorizontal: 0,
                                    padding: 5,
                                    width: 'auto',
                                }}
                                mouseOverTitle={actionMessages.delete}
                            />
                        )}
                        {/* Button to go complete signature */}
                        {hasToSign && (
                            <CButton
                                onPress={showSignatureRequest}
                                icon="signature"
                                minor
                                transparent
                                style={{
                                    marginHorizontal: 0,
                                    padding: 5,
                                    width: 'auto',
                                }}
                            />
                        )}
                        <CButton
                            onPress={showInfo}
                            icon="info_outline"
                            minor
                            transparent
                            style={{
                                marginHorizontal: 0,
                                padding: 5,
                                width: 'auto',
                            }}
                        />
                    </View>
                </TouchableView>
                {!!trackinUrlOn && (
                    <CText>
                        {format(contractMessages.trackingUrlOn)}{' '}
                        {new Date(trackinUrlOn).toLocaleDateString('de')}
                    </CText>
                )}
                {!!trackingUrl && (
                    <TouchableView
                        style={[style.horizontal, style.centeredItems]}
                        onPress={followTrackingUrl}
                    >
                        <CText
                            message={contractMessages.tracking}
                            style={{ color: theme.accentColor }}
                        />
                        <CIcon
                            icon="share"
                            tint={theme.accentColor}
                            style={style.leftPadded}
                            size={16}
                        />
                    </TouchableView>
                )}
            </View>
            {open &&
                envelope.files.map((f) => (
                    <ContractFile
                        key={f.documentId}
                        file={f as MContractFile}
                        contract={contract}
                        issueReload={issueReload}
                        signatureAvailable={false}
                    />
                ))}
        </>
    );
};
