import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCard, CIcon, CText } from '../../../../components';
import { dashboardMessages } from '../../Dashboard.messages';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { useFormat } from '../../../../utilities/intl';
import { ECollections } from '../../../../enums';
import { MContract, MWorkplace } from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { ContractTitle } from '../../../Contract/components/ContractTitle';
import { useNavigate } from '../../../../utilities/routing';
import { day } from '../../../../utilities/functions';

enum DCCMode {
    active = 'active',
    completed = 'completed',
    upcoming = 'upcoming',
    upcomingSoon = 'upcomingSoon',
    all = 'all',
}

export const DashboardContractCard: FC<{ contract: MContract }> = ({
    contract,
}) => {
    const { userData, userWorkplaces, getDataById } = useFireBase();
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const [workplace, setWorkplace] = useState<MWorkplace>();
    /**
     * effect to load workplace name
     */
    useEffect(() => {
        if (contract.workplaceId) {
            getDataById(
                ECollections.publicWorkplaces,
                contract.workplaceId,
            ).then((res) => {
                setWorkplace(new MWorkplace(res));
            });
        }
    }, [userWorkplaces, userData]);

    return (
        <CCard
            key={contract.documentId}
            style={{
                maxWidth: 350,
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderColor: theme.backgroundTertiaryColor,
                borderWidth: 1,
                borderStyle: 'solid',
            }}
            outsideStyle={{ marginHorizontal: 10 }}
            hoverEffects
        >
            {!!workplace && (
                <CText
                    style={style.horizontalHeavyPadded}
                    message={workplace.name}
                />
            )}
            <View
                style={[style.verticalHeavyPadded, style.horizontalHeavyPadded]}
            >
                <ContractTitle contract={contract} />
            </View>
            {contract.from > Date.now() && (
                <CText
                    secondaryHeadline
                    style={[
                        style.verticalHeavyPadded,
                        style.horizontalHeavyPadded,
                    ]}
                    message={
                        contract.from - Date.now() > day
                            ? format(dashboardMessages.contractStartsIn, {
                                  x: Math.floor(
                                      (contract.from - Date.now()) / day,
                                  ),
                              })
                            : format(dashboardMessages.contractStartsTomorrow)
                    }
                />
            )}
            <View
                style={[
                    style.horizontal,
                    style.centeredItems,
                    style.verticalHeavyPadded,
                    style.horizontalHeavyPadded,
                ]}
            >
                <CIcon
                    icon="calendar"
                    tint={theme.textSecondaryColor}
                    size={14}
                />
                <CText style={style.leftPadded}>
                    {/* {new Date(
                        negotiation.createdOn,
                    ).toLocaleDateString('de')} */}
                    {`${new Date(contract.from).toLocaleDateString(
                        'de',
                    )} - ${new Date(contract.to).toLocaleDateString('de')}`}
                </CText>
            </View>
            <View
                style={[
                    style.horizontalSplit,
                    {
                        borderTopColor: theme.backgroundTertiaryColor,
                        borderTopWidth: 1,
                        borderStyle: 'solid',
                    },
                ]}
            >
                <View style={[style.horizontalSpaced, style.flex1]}>
                    <CButton
                        title={dashboardMessages.openContract}
                        onPress={() =>
                            navigate('/contract/' + contract.documentId)
                        }
                        transparent
                        minor
                        small
                        style={{
                            paddingVertical: 15,
                            marginHorizontal: 0,
                            paddingHorizontal: 0,
                        }}
                    />
                </View>
                <View style={style.verticalThinSeparator} />
                <View style={[style.horizontalSpaced, style.flex1]}>
                    <CButton
                        title={dashboardMessages.openContractChat}
                        onPress={() => navigate('/chat/' + contract.chatId)}
                        transparent
                        minor
                        small
                        style={{
                            paddingVertical: 15,
                            marginHorizontal: 0,
                            paddingHorizontal: 0,
                        }}
                    />
                </View>
            </View>
        </CCard>
    );
};
