import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { CText } from '../CText';
import { ICPickerProps } from './ICPickerProps';
import './picker.css';
import { AdditionalInputs } from './components/AdditionalInputs';
import { useAutoFormat } from '../../../utilities/intl/useFormat';
import Select, { StylesConfig } from 'react-select';

export const CPicker: FC<ICPickerProps> = ({
    value,
    valueOther,
    values,
    onChange,
    onChangeOther,
    allowOther,
    multiValues,
    onChangeMulti,
    title,
    validity,
    horizontal,
    cy,
    placeholder: propPlaceholder,
    style: propStyle,
    keepOpen,
    allowEmpty,
    multiLimit,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const format = useAutoFormat();
    /**
     * memoized other constraint
     */
    const other = useMemo(() => {
        return value === 'other';
    }, [value]);
    /**
     * memoized other items
     */
    const otherItems = useMemo(
        () =>
            allowOther
                ? [
                      {
                          value: 'other',
                          label: format(generalMessages.other),
                          hidden: false,
                          isDisabled: false,
                      },
                  ]
                : [],
        [allowOther],
    );
    /**
     * memoized placeholder (either empty value / label or placeholder from props)
     */
    const placeholder = useMemo(() => {
        const complexPlaceholder = values.find((v) => !v.value);
        const label = complexPlaceholder?.label || propPlaceholder;
        const hidden = complexPlaceholder ? complexPlaceholder.hidden : false;
        return label ? { label, hidden } : undefined;
    }, [values, propPlaceholder]);
    /**
     * memoized items merged with other option
     */
    const items = useMemo(() => {
        const next = [...values, ...otherItems].filter((v) => !!v.value);

        if (placeholder && allowEmpty && value) {
            next.unshift({ ...placeholder, value: undefined });
        }

        return next.map((v) => {
            return {
                ...v,
                label: format(v.label),
                id: v.value,
                isDisabled: !!(
                    multiLimit &&
                    multiValues &&
                    multiLimit <= multiValues.length
                ),
            };
        });
    }, [
        values,
        otherItems,
        allowEmpty,
        placeholder,
        multiLimit,
        multiValues,
        value,
    ]);
    /**
     * memoized styles for picker
     */
    const pickerStyles: StylesConfig = useMemo(
        () => ({
            control: (base, { menuIsOpen }) => ({
                ...base,
                'backgroundColor': theme.backgroundMainColor,
                'color': theme.textMainColor,
                'fontFamily': theme.fontMain,
                'borderColor': menuIsOpen ? theme.accentColor : 'transparent',
                'borderWidth': 1,
                'borderRadius': 10,
                ':hover': {
                    borderColor: menuIsOpen ? theme.accentColor : 'transparent',
                },
                'WebkitBoxShadow': 'var(--shadowColor) 0px 1px 1px 0px',
                'boxShadow': 'var(--shadowColor) 0px 1px 1px 0px',
            }),
            option: (base, { data, isDisabled, isFocused, isSelected }) => ({
                ...base,
                'backgroundColor': isDisabled
                    ? theme.borderColor
                    : isSelected
                    ? theme.backgroundSecondaryColor
                    : isFocused
                    ? theme.accentColor
                    : theme.backgroundMainColor,
                'color': isDisabled
                    ? theme.textMainColor
                    : isSelected
                    ? theme.textSecondaryColor
                    : theme.textMainColor,
                'cursor': isDisabled ? 'not-allowed' : 'default',
                'fontFamily': theme.fontMain,
                ':active': {
                    ...base[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? theme.accentColor
                            : theme.accentColor
                        : undefined,
                },
            }),
            menuList: (base, props) => ({
                ...base,
                backgroundColor: theme.backgroundMainColor,
                fontFamily: theme.fontMain,
            }),
            container: (base) => ({
                ...base,
                backgroundColor: theme.backgroundMainColor,
                fontFamily: theme.fontMain,
            }),
            input: (base) => ({
                ...base,
                fontFamily: theme.fontMain,
                color: theme.textMainColor,
            }),
            placeholder: (base) => ({
                ...base,
                color: theme.textSecondaryColor,
                fontFamily: theme.fontMain,
            }),
            singleValue: (base, { data }) => ({
                ...base,
                color: theme.textMainColor,
                fontFamily: theme.fontMain,
            }),
            multiValueLabel: (base) => ({
                ...base,
                backgroundColor: theme.backgroundMainColor,
                borderColor: theme.accentColor,
                borderWidth: 1,
                borderRadius: 5,
                color: theme.accentColor,
            }),
            multiValue: (base, props) => ({
                ...base,
                backgroundColor: theme.backgroundMainColor,
                borderColor: theme.accentColor,
                borderWidth: 1,
                borderRadius: 5,
                borderStyle: 'solid',
            }),
            menu: (base) => ({
                ...base,
                width: 'max-content',
                minWidth: '100%',
            }),
        }),
        [theme],
    );
    /**
     * return
     */
    return (
        <View
            style={[
                style.verticalPadded,
                horizontal && style.horizontal,
                horizontal && style.centeredItems,
                { zIndex: 1 },
            ]}
        >
            {!!title && (
                <CText
                    style={horizontal && style.horizontalPadded}
                    message={title}
                />
            )}
            <Select
                {...{ dataSet: { cy } }}
                id={cy}
                value={
                    multiValues
                        ? items.filter((option) =>
                              multiValues.find((mV) => option.value === mV),
                          )
                        : items.find((option) => option.value === value)
                }
                onChange={(v: any) => {
                    if (
                        (v.length || multiValues?.length === 1) &&
                        onChangeMulti
                    ) {
                        onChangeMulti(v.map((ob: any) => ob.value));
                    }
                    if (onChange) onChange(v.value);
                }}
                inputId={cy + '-input'}
                classNamePrefix={'cinput'}
                instanceId={cy + '-instance'}
                options={items}
                // styles={style}
                styles={pickerStyles}
                placeholder={
                    placeholder ? format(placeholder.label) : undefined
                }
                maxMenuHeight={250}
                menuPlacement="auto"
                //@ts-ignore
                menuPortalTarget={document.getElementById('root')}
                isMulti={!!multiValues}
                hideSelectedOptions={!!multiValues}
                closeMenuOnSelect={!keepOpen}
                onMenuOpen={() => {
                    setTimeout(() => {
                        // @ts-ignore
                        const selectedEl = document.getElementsByClassName(
                            'cinput__option--is-selected',
                        )[0];
                        if (selectedEl) {
                            selectedEl.scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest',
                                inline: 'start',
                            });
                        }
                    }, 15);
                }}
            />
            {/* <select
                {...{ 'data-cy': cy }}
                className="fds"
                onChange={(event) => {
                    // @ts-ignore
                    onChange(event.target.value);
                }}
                value={value}
                // @ts-ignore
                style={propStyle || undefined}
            >
                {!!placeholder && (
                    <option hidden={placeholder.hidden}>
                        {format(placeholder.label)}
                    </option>
                )}
                {items.map((v, i) => (
                    <option
                        key={i}
                        value={v.value}
                        hidden={v.hidden}
                        disabled={v.disabled}
                    >
                        {v.label}
                    </option>
                ))}
            </select> */}
            <AdditionalInputs
                other={other}
                values={items}
                multiValues={multiValues}
                onChangeOther={onChangeOther}
                valueOther={valueOther}
                onChangeMulti={onChangeMulti}
                validity={validity}
            />
        </View>
    );
};
