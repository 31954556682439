import React, { FC, useEffect, useRef } from 'react';
import { View } from 'react-native';
import SignatureScreen from 'react-signature-canvas';
import { useStyle, useTheme } from '../../utilities/styles';
import { CButton, CText } from '../elements';
import { signatureMessages } from './Signature.messages';
import { ISignatureProps } from './Signature';

export const Signature: FC<ISignatureProps> = ({
    onOK,
    text,
    disabled,
    okTitle,
}) => {
    const style = useStyle();
    const { theme } = useTheme();

    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current) {
            if (disabled) {
                ref.current.off();
            } else {
                ref.current.on();
            }
        }
    }, [ref, disabled]);

    return (
        <View>
            <CText secondaryHeadline>{text}</CText>
            <View
                style={[
                    {
                        marginVertical: 10,
                        borderRadius: 5,
                        overflow: 'hidden',
                        backgroundColor: 'white',
                        borderColor: theme.backgroundTertiaryColor,
                        borderWidth: 1,
                        borderStyle: 'solid',
                    },
                    disabled && { backgroundColor: theme.borderColor },
                ]}
            >
                <CText
                    style={{ position: 'absolute' }}
                    message={signatureMessages.placeSignatureHere}
                    selectable={false}
                />
                <SignatureScreen
                    ref={(next) => {
                        ref.current = next;
                    }}
                    penColor={'black'}
                    backgroundColor={'transparent'}
                    clearOnResize={false}
                />
            </View>
            <View style={style.horizontalSpaced}>
                <CButton
                    title={signatureMessages.clear}
                    onPress={() => {
                        ref.current.clear();
                    }}
                    warning
                />
                <CButton
                    cy={'apply-signature'}
                    title={okTitle || signatureMessages.apply}
                    onPress={async () => {
                        await onOK(ref.current.toDataURL());
                    }}
                    disabled={disabled}
                    disableOnClick
                />
            </View>
        </View>
    );
};
