import React, { FC, useMemo } from 'react';
import { CTable } from '../../../components';
import { MJob, MWage } from '../../../models';
import { useFormat } from '../../../utilities/intl';
import {
    generalMessages,
    educationMessages,
    specializationMessages,
    positionMessages,
} from '../../../utilities/messages';
import { employmentTypeMessages } from '../../../utilities/messages/employmentType.messages';
import { jobMessages } from '../job.messages';
import { EProfession } from '../../../enums';
import { wageMessages } from '../../../components/Wage/wage.messages';
import { convertNumberToCurrencyString } from '../../../utilities/functions';
import { useEnvironment } from '../../../utilities/contexts';

interface IJobTableProps {
    job: MJob;
    pure?: boolean;
}

export const JobTable: FC<IJobTableProps> = ({ job, pure }) => {
    const format = useFormat();
    const { region } = useEnvironment();
    /**
     * rows
     */
    const rows = useMemo(() => {
        const next = [];
        next.push({
            key: format(jobMessages.fromTo),
            value: job.fromNowUntillUndefined
                ? format(jobMessages.fromNow)
                : job.from > Date.now()
                ? `${new Date(job.from).toLocaleDateString('de')} - ${
                      job.to
                          ? new Date(job.to).toLocaleDateString('de')
                          : format(jobMessages.unlimited)
                  }`
                : format(jobMessages.onRequest),
        });
        if (job.useFullAddress && job.fullAddress) {
            next.push({
                key: format(jobMessages.location),
                value: `${job.fullAddress.zipCode} ${job.fullAddress.city}, ${job.fullAddress.street} ${job.fullAddress.number}`,
            });
        }
        if (job.accommodation) {
            next.push({
                key: format(jobMessages.accommodation),
                value:
                    job.accommodation === 'other'
                        ? job.otherSelfAccommodation
                        : // @ts-ignore
                        jobMessages[job.accommodation]
                        ? format(
                              // @ts-ignore
                              jobMessages[job.accommodation],
                          )
                        : job.accommodation,
            });
        }
        next.push({
            key: format(jobMessages.employType),
            value: format(employmentTypeMessages[job.type]),
        });
        next.push({
            key: format(jobMessages.wageLevel),
            value: isNaN(Number(job.wageLevel))
                ? format(jobMessages[job.wageLevel])
                : format(jobMessages.average),
        });
        next.push({
            key: format(jobMessages.travelPay),
            value: job.travelPay
                ? format(generalMessages.true)
                : format(generalMessages.false),
        });
        if (job.employeeAcademicTitle) {
            next.push({
                key: format(jobMessages.employeeAcademicTitle),
                value: format(generalMessages.true),
            });
        }
        if (job.liability) {
            next.push({
                key: format(jobMessages.liability),
                value: job.liability,
            });
        }
        if (job.workingTimes) {
            next.push({
                key: format(jobMessages.workingTimes),
                value: job.workingTimes,
            });
        }
        const addWageRows = (wage: MWage, type?: keyof typeof wageMessages) => {
            if (wage.wage) {
                next.push({
                    key: type
                        ? `${format(wageMessages.regularWage)} ${format(
                              wageMessages[type],
                          )}`
                        : format(wageMessages.regularWage),
                    value: convertNumberToCurrencyString(
                        wage.wage + wage.regularWage,
                        region,
                    ),
                });
                if (wage.standbyWage) {
                    next.push({
                        key: type
                            ? `${format(wageMessages.standbyWage)} ${format(
                                  wageMessages[type],
                              )}`
                            : format(wageMessages.standbyWage),
                        value: convertNumberToCurrencyString(
                            wage.wage + wage.standbyWage,
                            region,
                        ),
                    });
                }
                if (wage.onCallActiveWage) {
                    next.push({
                        key: type
                            ? `${format(
                                  wageMessages.onCallActiveWage,
                              )} ${format(wageMessages[type])}`
                            : format(wageMessages.onCallActiveWage),
                        value: convertNumberToCurrencyString(
                            wage.wage + wage.onCallActiveWage,
                            region,
                        ),
                    });
                }
                if (wage.onCallPassiveWage) {
                    next.push({
                        key: type
                            ? `${format(
                                  wageMessages.onCallPassiveWage,
                              )} ${format(wageMessages[type])}`
                            : format(wageMessages.onCallPassiveWage),
                        value: convertNumberToCurrencyString(
                            wage.wage + wage.onCallPassiveWage,
                            region,
                        ),
                    });
                }
            }
        };
        addWageRows(job.wage);
        if (job.wage.useNightWage) {
            addWageRows(job.wage.nightWage, 'nightRate');
        }
        if (job.wage.useSundayWage) {
            addWageRows(job.wage.sundayWage, 'sundayRate');
        }
        if (job.wage.useHolidayWage) {
            addWageRows(job.wage.holidayWage, 'holidayRate');
        }
        if (!pure) {
            if (
                job.employeeProfession &&
                job.employeeProfession !== EProfession.undefined
            ) {
                next.push({
                    key: format(jobMessages.employeeProfession),
                    value: format(generalMessages[job.employeeProfession]),
                });
            }
            if (!job.useFullAddress) {
                next.push({
                    key: format(jobMessages.location),
                    value: job.location,
                });
            }
            next.push({
                key: format(jobMessages.educations),
                value: job.employeeEducations
                    .map((education) =>
                        format(
                            educationMessages[
                                education as keyof typeof educationMessages
                            ],
                        ),
                    )
                    .join(', '),
            });
            if (job.employeeSpecializations.length) {
                next.push({
                    key: format(jobMessages.specializations),
                    value: job.employeeSpecializations
                        .map((specialization) =>
                            format(
                                specializationMessages[
                                    specialization as keyof typeof specializationMessages
                                ],
                            ),
                        )
                        .join(', '),
                });
            }
            if (job.employeePosition) {
                const typedPosition =
                    job.employeePosition as keyof typeof positionMessages;
                next.push({
                    key: format(jobMessages.employeePosition),
                    value: positionMessages[typedPosition]
                        ? format(positionMessages[typedPosition])
                        : '-',
                });
            }
            if (job.employeeAcademicTitle) {
                next.push({
                    key: format(jobMessages.employeeAcademicTitle),
                    value: job.employeeAcademicTitle
                        ? format(generalMessages.true)
                        : format(generalMessages.false),
                });
            }
            next.push({
                key: format(jobMessages.yoe),
                value: `${job.employeeYoe}`,
            });
        }
        return next;
    }, []);
    /**
     * render
     */
    return (
        <CTable
            headers={[{ key: 'key' }, { key: 'value' }]}
            hideHeaders
            rows={[...rows].map((entry) => {
                return {
                    values: [
                        {
                            key: 'key',
                            bold: true,
                            value: entry.key,
                        },
                        {
                            key: 'value',
                            value: entry.value,
                            flex: 2,
                        },
                    ],
                };
            })}
        />
    );
};
