import { EApplicationType, EField, EProfession, ERegion } from '../enums';
import { MRates } from './MRates';
import { MWorkplace } from './MWorkplace';
import { MBasicContract } from './MBasicContract';
import { IDayAvailability } from './MAvailability';
import { ETimesheetType } from '../enums/ETImesheetType';
import { MProfessionalInfo } from './MProfessionalInfo';
/**
 * contract for reguar flow. extends basic with values that are relevant for billing / tracking contract details
 */
export class MContract extends MBasicContract {
    public type = EApplicationType.user;

    public canceledBy?: string;
    public cancelReason?: string;
    public cancelByAgencyAfterAgreement?: boolean;

    public applicationId?: string;
    public requestId?: string;

    public changedBy = '';

    public usualWage = 0;
    public fixedJobWage = false;
    public wage = new MRates();
    public from = 0;
    public to = 0;
    public tos: {
        to: number;
        approved?: number;
        approvedBy?: string;
        from?: string;
        createdOn: number;
        og?: boolean;
    }[] = [];
    public days: IDayAvailability[] = [];
    public originalParameters = {
        wage: new MRates(),
        from: 0,
        to: 0,
        days: [] as IDayAvailability[],
    };

    public field?: EField;
    public profession?: EProfession;
    public education?: string;

    public employeeId = '';
    public profileId?: string;
    public employee?: MProfessionalInfo;
    public agencyId = '';

    public employerId = '';
    public signingEmployerId = '';
    public workplaceId = '';
    public workplace?: MWorkplace;
    public workplaceUsers: string[] = [];

    public workplaceLeadMail = '';
    public workplaceLeadName = '';

    public employeeSignatureTimestamp?: number;
    public workplaceSignatureTimestamp?: number;

    public jobId?: string;

    public region = ERegion.de;
    public timesheetType = ETimesheetType.undefined;
    public timesheetAlerts = false;

    public activeDates: string[] = [];
    public activeMonths: number[] = [];

    public january?: boolean;
    public february?: boolean;
    public march?: boolean;
    public april?: boolean;
    public may?: boolean;
    public june?: boolean;
    public july?: boolean;
    public august?: boolean;
    public september?: boolean;
    public october?: boolean;
    public november?: boolean;
    public december?: boolean;
    public activeYears?: any;

    constructor(params: Partial<MContract> = {}) {
        super(params);
        Object.assign(this, params);

        MContract.fixConstructor(this, params);
    }

    static fixConstructor = (that: MContract, params: Partial<MContract>) => {
        if (params.employee) {
            that.employee = new MProfessionalInfo(params.employee);
        }

        if (params.workplace) {
            that.workplace = new MWorkplace(params.workplace);
        }

        if (params.originalParameters && params.originalParameters.wage) {
            that.originalParameters.wage = new MRates(
                params.originalParameters.wage,
            );
        }

        if (params.wage) {
            /**
             * * legacy wage fix
             */
            if (typeof params.wage === 'number') {
                const uPs = params as any;

                that.wage = new MRates({
                    wage: uPs.wage,
                    regularWage: uPs.regularWage,
                    standbyWage: uPs.standbyWage,
                    onCallActiveWage: uPs.onCallActiveWage,
                    onCallPassiveWage: uPs.onCallPassiveWage,
                });
            } else {
                /**
                 * wage assign
                 */
                that.wage = new MRates(params.wage);
            }
        }
        if (params.from && params.to) {
            const nextActiveDates: string[] = [];
            const nextActiveMonths: number[] = [];
            const nextActiveYears: number[] = [];
            let pivot = params.from;
            const toDate = new Date(params.to);
            toDate.setDate(toDate.getDate() + 1);
            const end = toDate.getTime();
            while (pivot < end) {
                const curDate = new Date(pivot);
                const standardWeekDay = curDate.getDay();
                const curWeekDay = !standardWeekDay ? 7 : standardWeekDay;
                const dayTime = that.days.find((d) => d.day === curWeekDay);
                if (!that.days.length || dayTime) {
                    nextActiveDates.push(curDate.toLocaleDateString('de'));
                    const curMonth = curDate.getMonth();
                    const curYear = curDate.getFullYear();
                    if (!nextActiveMonths.includes(curMonth)) {
                        nextActiveMonths.push(curMonth);
                    }
                    if (!nextActiveYears.includes(curYear)) {
                        nextActiveYears.push(curYear);
                    }
                }
                curDate.setDate(curDate.getDate() + 1);
                pivot = curDate.getTime();
            }

            that.activeDates = nextActiveDates;
            that.activeMonths = nextActiveMonths;
            that.activeYears = nextActiveYears.reduce((acc, y) => {
                acc[`y${y}`] = true;
                return acc;
            }, {} as any);
        }
        that.january = that.activeMonths.includes(0);
        that.february = that.activeMonths.includes(1);
        that.march = that.activeMonths.includes(2);
        that.april = that.activeMonths.includes(3);
        that.may = that.activeMonths.includes(4);
        that.june = that.activeMonths.includes(5);
        that.july = that.activeMonths.includes(6);
        that.august = that.activeMonths.includes(7);
        that.september = that.activeMonths.includes(8);
        that.october = that.activeMonths.includes(9);
        that.november = that.activeMonths.includes(10);
        that.december = that.activeMonths.includes(11);
    };
}
