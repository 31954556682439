import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useParams, useSecureNavigate } from '../../utilities/routing';
import {
    MContract,
    MProfessionalProfile,
    MShift,
    MTimesheet,
    MWorkplace,
} from '../../models';
import {
    CButton,
    CCard,
    CPicker,
    CText,
    Signature,
    Spinner,
} from '../../components';
import { useFireBase } from '../../utilities/firebase';
import { ECollections, ETimesheetType } from '../../enums';
import { useStyle } from '../../utilities/styles';
import {
    actionMessages,
    generalMessages,
    monthMessages,
} from '../../utilities/messages';
import { ShiftRow } from './components/ShiftRow';
import { ShiftRowEdit } from './components/ShiftRowEdit';
import { timesheetMessages } from './timesheet.messages';
import { useDialog, useUnsavedChangesDialog } from '../../utilities/dialog';
import { useLock } from '../../utilities/hooks';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    weeksInYear,
} from '../../utilities/functions';
import { ContractTitle } from '../Contract/components/ContractTitle';
import { ScrollProvider } from '../../utilities/contexts';
import { capitalize, useFormat } from '../../utilities/intl';
import { isAgencyUser } from '../../utilities/auth';
/**
 * Time tracking view
 * > https://trello.com/c/3ulgKNrj/289-timetracking-app-bauen-stundenzettel-designen-und-zu-contract-hochladen
 */
export const Timesheet: FC<{
    embedded?: boolean;
    signOff?: boolean;
    timesheetId?: string;
    onComplete?: (url?: string) => void;
}> = ({ embedded, signOff, timesheetId: propsTimesheetId, onComplete }) => {
    // global state
    const { lock } = useLock();
    const style = useStyle();
    const format = useFormat();
    const dialog = useDialog();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const { contractId, timesheetId: paramsTimesheetId } = useParams();
    const { getDataById, getDataIndex, callFunction, userData } = useFireBase();
    // local state
    const timesheetId = useMemo(
        () => propsTimesheetId || paramsTimesheetId,
        [propsTimesheetId, paramsTimesheetId],
    );
    const [contract, setContract] = useState<MContract>();
    const [workplace, setWorkplace] = useState<MWorkplace>();
    const [timesheet, setTimesheet] = useState<MTimesheet>();
    const [shifts, setShifts] = useState<MShift[]>([]);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [cellWidth, setCellWidth] = useState(0);
    /**
     * callback to handle shhift change
     */
    const onChangeShift = useCallback(
        (index: number, next: Partial<MShift>) =>
            setShifts((prev) => {
                if (prev.length < index) return prev;
                /**
                 * new shift object with updated value
                 */
                const shoi = new MShift({ ...prev[index], ...next });
                /**
                 * recalculate from
                 */
                shoi.from = new Date(
                    +shoi.year,
                    +shoi.month - 1,
                    +shoi.date,
                    shoi.fromHour,
                    shoi.fromMinute,
                ).getTime();
                /**
                 * recalculate to
                 */
                shoi.to = new Date(
                    +shoi.year,
                    +shoi.month - 1,
                    +shoi.date,
                    shoi.toHour,
                    shoi.toMinute,
                ).getTime();
                /**
                 * create new array
                 */
                const toReturn = [...prev];
                /**
                 * set index to new shift object
                 */
                toReturn[index] = shoi;

                return toReturn;
            }),
        [],
    );
    /**
     * callback to add a new shift
     */
    const addShift = useCallback(() => {
        if (!timesheet) return;
        setShifts((prev) => {
            let nextShiftDate =
                timesheet.type === ETimesheetType.weekly
                    ? convertWeekAndYearToDate(
                          new Date().getFullYear(),
                          timesheet.week || 0,
                      )
                    : new Date(new Date().getFullYear(), timesheet.month || 0);
            if (prev.length) {
                nextShiftDate = new Date(prev[prev.length - 1].from + day);
            }
            const date = nextShiftDate.getDate();
            const month = nextShiftDate.getMonth();
            const year = nextShiftDate.getFullYear();
            return [
                ...prev,
                new MShift({
                    date: `${date}`,
                    month: `${month + 1}`,
                    year: `${year}`,
                    fromHour: 9,
                    fromMinute: 0,
                    from: new Date(year, month, date, 9, 0).getTime(),
                    toHour: 17,
                    toMinute: 0,
                    to: new Date(year, month, date, 17, 0).getTime(),
                    complete: true,
                }),
            ];
        });
    }, [timesheet]);
    /**
     * callback to remove a shift from index
     */
    const removeShift = useCallback((index: number) => {
        setShifts((prev) => {
            const next = [...prev];
            next.splice(index, 1);
            return next;
        });
    }, []);
    /**
     * callback to handle timesheet save action
     */
    const handleSave = useCallback(
        async (signature?: string) => {
            if (!timesheet) return;
            let complete = false;
            let pdf = false;
            let signeeMail =
                timesheet.workplaceLeadMail ||
                contract?.workplaceLeadMail ||
                workplace?.leadMail;
            let qr = false;
            let ok = true;
            if (!signature) {
                /**
                 * ask agency user / initial timesheet creator to mark timesheet as complete and or create a pdf
                 */
                ok = await dialog({
                    icon: 'question',
                    title: timesheetMessages.saveAndComplete,
                    message: timesheetMessages.saveAndComplete,
                    buttons: [
                        {
                            text: timesheetMessages.saveAndComplete,
                            onPress: () => (complete = true),
                            disabled: () => timesheet.complete,
                        },
                        {
                            text: actionMessages.save,
                            disabled: () => timesheet.complete,
                        },
                        {
                            text: timesheetMessages.createTimesheetDocument,
                            onPress: () => (pdf = true),
                        },
                    ],
                    verticalButtons: true,
                });
            }
            if (!ok) return;
            if (complete) {
                /**
                 * confirm completion of timesheet
                 */
                ok = !(await dialog({
                    icon: 'warning',
                    title: timesheetMessages.confirmComplete,
                    message: timesheetMessages.confirmCompleteText,
                    buttons: [{ text: actionMessages.confirm }],
                    cancelButton: { text: actionMessages.cancel },
                }));
            }
            if (!ok) return;
            if (signOff) {
                /**
                 * ask signee for secondary handling of signature
                 */
                ok = await dialog({
                    title: timesheetMessages.secondarySigneeMailTitle,
                    message: timesheetMessages.secondarySigneeMailText,
                    textInputs: [
                        {
                            id: 'signeeMail',
                            title: timesheetMessages.secondarySigneeMail,
                            defaultValue: signeeMail,
                        },
                    ],
                    buttons: [
                        {
                            text: timesheetMessages.skipSecondary,
                            onPress: () => {
                                signeeMail = undefined;
                            },
                        },
                        {
                            text: timesheetMessages.sendMail,
                            onPress: (inputs) => {
                                signeeMail = inputs?.find(
                                    (i) => i.id === 'signeeMail',
                                )?.value;
                            },
                        },
                        {
                            text: timesheetMessages.showQrCode,
                            onPress: () => {
                                qr = true;
                            },
                        },
                    ],
                    cancelButton: { text: actionMessages.cancel },
                });
            }
            if (!ok) return;
            const unlock = lock();
            /**
             * start saving of timesheet
             */
            const nextDocId = await callFunction('saveTimesheet', {
                ...timesheet,
                shifts,
            });
            let result: any = {};
            /**
             * create timesheet file if signed or whish for pdf
             */
            if (pdf || signature) {
                result = await callFunction('createTimesheetFile', {
                    contractId: timesheet.contractId,
                    timesheetId: timesheet.documentId || nextDocId,
                    signature,
                    signeeMail,
                    forceExpose: !!qr,
                });
            }
            unlock();
            if (onComplete) {
                onComplete(qr ? result.url : undefined);
            } else if (!embedded) {
                secureNavigate(-1, { force: true });
            } else {
                secureNavigate('/', { force: true });
            }
        },
        [timesheet, shifts, signOff, workplace, contract],
    );
    /**
     * callback to issue expose of current sheet
     * TODO: expose in a meaningfull flow
     */
    const exposeTimesheet = useCallback(async () => {
        let mail = '';
        if (contract && contract.profileId) {
            const unlock = lock();
            const p = await getDataById(
                ECollections.profProfiles,
                contract.profileId,
            );
            const profile = new MProfessionalProfile(p);
            if (profile.mail) {
                mail = p.mail;
            }
            unlock();
        }
        const ok = await dialog({
            icon: 'rocketman',
            title: timesheetMessages.enterEmployeeMail,
            message: timesheetMessages.enterEmployeeMailDesc,
            textInputs: [
                { id: 'mail', title: generalMessages.mail, defaultValue: mail },
            ],
            buttons: [
                {
                    text: actionMessages.expose,
                    disabled: (inputs) =>
                        !inputs?.find(({ id }) => id === 'mail')?.value,
                    onPress: (inputs) =>
                        (mail = inputs?.find(({ id }) => id === 'mail')?.value),
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });
        if (!ok) return;
        const unlock = lock();
        const nextDocId = await callFunction('saveTimesheet', {
            ...timesheet,
            exposed: true,
            shifts,
            mail,
        });
        console.log(timesheet?.contractId, nextDocId);
        unlock();
        secureNavigate(-1, { force: true });
    }, [timesheet, shifts]);
    /**
     * unsaved changes dialog
     */
    const unsavedChangesDialog = useUnsavedChangesDialog();
    /**
     * effect to load the contract the time sheet is for
     */
    useEffect(() => {
        if (!contractId || embedded) return;

        getDataById(ECollections.contracts, contractId).then((c) =>
            setContract(new MContract(c)),
        );
    }, [contractId]);
    /**
     * effect to load contractworkpalce
     */
    useEffect(() => {
        const wpId = contract?.workplaceId || timesheet?.workplaceId;
        if (!wpId) return;
        getDataById(ECollections.publicWorkplaces, wpId).then((res) => {
            const next = new MWorkplace(res);
            setWorkplace(next);
        });
    }, [contract, timesheet]);
    /**
     * effect to load all data related to contract after contract did load
     */
    useEffect(() => {
        if (!embedded && contract && !timesheetId) {
            const now = new Date();
            const week = getWeekNumber(now);
            setTimesheet(
                new MTimesheet({
                    contractId: contract.documentId,
                    agencyId: contract.agencyId,
                    employeeId: contract.employeeId,
                    workplaceId: contract.workplaceId,
                    workplaceLeadMail: contract.workplaceLeadMail || undefined,
                    type: contract.timesheetType,
                    week,
                    year: now.getFullYear(),
                    month: now.getMonth(),
                }),
            );
        } else if (!embedded && !contract) {
            return;
        } else if (timesheetId && contractId) {
            const timesheetCollection = `${ECollections.contracts}/${contractId}/${ECollections.timesheets}`;
            getDataById(timesheetCollection, timesheetId).then((ts) => {
                const next = new MTimesheet(ts);
                setTimesheet(next);
            });
        }
    }, [contract, contractId, timesheetId, embedded]);
    /**
     * effect to load current shifts
     */
    useEffect(() => {
        if (timesheet?.documentId) {
            getDataIndex(
                `${ECollections.contracts}/${contractId}/${ECollections.timesheets}/${timesheet.documentId}/${ECollections.shifts}`,
            ).then((ss) =>
                setShifts(
                    (ss as MShift[])
                        .map((s) => new MShift(s))
                        .sort(
                            (a, b) =>
                                +(
                                    a.month +
                                    (+a.date).toLocaleString('de', {
                                        minimumIntegerDigits: 2,
                                    })
                                ) -
                                +(
                                    b.month +
                                    (+b.date).toLocaleString('de', {
                                        minimumIntegerDigits: 2,
                                    })
                                ),
                        ),
                ),
            );
        } else if (timesheet && !timesheet.documentId && contract) {
            const from =
                timesheet.type === ETimesheetType.weekly
                    ? convertWeekAndYearToDate(
                          new Date().getFullYear(),
                          timesheet.week || 0,
                      )
                    : new Date(new Date().getFullYear(), timesheet.month || 0);
            const to = new Date(from);
            if (timesheet.type === ETimesheetType.montly) {
                to.setMonth(to.getMonth() + 1);
            } else if (timesheet.type === ETimesheetType.weekly) {
                to.setDate(to.getDate() + 7);
            }
            console.log(from.toLocaleDateString('de'));
            console.log(to.toLocaleDateString('de'));
            /**
             * init default shifts
             */
            setShifts(
                contract.activeDates
                    .filter((s) => {
                        if (timesheet.type === ETimesheetType.undefined)
                            return true;
                        const ss = s.split('.');
                        const d = new Date(+ss[2], +ss[1] - 1, +ss[0]);
                        return (
                            d.getTime() >= from.getTime() &&
                            d.getTime() < to.getTime()
                        );
                    })
                    .map((s) => {
                        const ss = s.split('.');
                        const d = new Date(+ss[2], +ss[1] - 1, +ss[0]);
                        const data = contract.days.find(
                            (v) => v.day === d.getDay(),
                        );
                        return new MShift({
                            date: d.getDate().toString(),
                            month: (d.getMonth() + 1).toString(),
                            year: d.getFullYear().toString(),
                            fromHour: data?.from || 9,
                            fromMinute: 0,
                            from: new Date(
                                +ss[2],
                                +ss[1] - 1,
                                +ss[0],
                                data?.from || 9,
                                0,
                            ).getTime(),
                            toHour: data?.to || 17,
                            toMinute: 0,
                            to: new Date(
                                +ss[2],
                                +ss[1] - 1,
                                +ss[0],
                                data?.to || 17,
                                0,
                            ).getTime(),
                            complete: true,
                        });
                    }),
            );
        }
    }, [timesheet, contract, contractId]);
    /**
     * set navigation lock
     */
    useEffect(
        () => setNavigationLock(async () => !(await unsavedChangesDialog())),
        [unsavedChangesDialog],
    );
    /**
     * return spinner during load
     */
    if (!timesheet) {
        return <Spinner />;
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            {!embedded && (
                <View style={style.headlineCard}>
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <CText
                            style={style.leftPadded}
                            message={timesheetMessages.timesheet}
                            headline
                        >
                            :
                        </CText>
                        {contract && (
                            <ContractTitle
                                contract={contract}
                                horizontalPadded
                            />
                        )}
                    </View>
                </View>
            )}
            {timesheet.documentId ? (
                timesheet.type !== ETimesheetType.undefined && (
                    <CCard embedded={embedded} style={style.horizontal}>
                        <View style={style.horizontal}>
                            <CText message={timesheetMessages.interval} />
                            <CText
                                style={style.horizontalPadded}
                                message={
                                    timesheetMessages[
                                        timesheet.type === ETimesheetType.montly
                                            ? 'intervalMonthly'
                                            : timesheet.type ===
                                              ETimesheetType.weekly
                                            ? 'intervalWeekly'
                                            : 'intervalUndefined'
                                    ]
                                }
                            />
                        </View>
                        <View style={style.horizontal}>
                            {timesheet.month !== undefined && (
                                <View style={style.horizontal}>
                                    <CText message={generalMessages.month} />
                                    <CText
                                        style={style.horizontalPadded}
                                        message={
                                            monthMessages[
                                                Object.keys(
                                                    monthMessages,
                                                ).filter(
                                                    (k) => !k.includes('Short'),
                                                )[
                                                    timesheet.month
                                                ] as keyof typeof monthMessages
                                            ]
                                        }
                                    />
                                </View>
                            )}
                            {timesheet.week !== undefined &&
                                timesheet.type === ETimesheetType.weekly && (
                                    <View style={style.horizontal}>
                                        <CText message={generalMessages.week} />
                                        <CText
                                            style={style.horizontalPadded}
                                            message={`${timesheet.week}`}
                                        />
                                    </View>
                                )}
                        </View>
                    </CCard>
                )
            ) : (
                <CCard embedded={embedded} style={style.horizontal}>
                    <CPicker
                        title={timesheetMessages.interval}
                        values={[
                            {
                                value: ETimesheetType.montly,
                                label: timesheetMessages.intervalMonthly,
                            },
                            {
                                value: ETimesheetType.weekly,
                                label: timesheetMessages.intervalWeekly,
                            },
                            {
                                value: ETimesheetType.undefined,
                                label: timesheetMessages.intervalUndefined,
                            },
                        ]}
                        value={timesheet.type}
                        onChange={(next) =>
                            setTimesheet(
                                (prev) =>
                                    new MTimesheet({
                                        ...prev,
                                        type: next as ETimesheetType,
                                    }),
                            )
                        }
                    />
                    {timesheet && timesheet.type !== ETimesheetType.undefined && (
                        <CPicker
                            title={generalMessages.month}
                            value={(timesheet.month || 0) + 1}
                            values={Object.keys(monthMessages)
                                .filter((m) => !m.includes('Short'))
                                .map((m, i) => ({
                                    label: monthMessages[
                                        m as keyof typeof monthMessages
                                    ],
                                    value: i + 1,
                                }))}
                            onChange={(v) =>
                                setTimesheet(
                                    (prev) =>
                                        new MTimesheet({
                                            ...prev,
                                            month: v - 1,
                                        }),
                                )
                            }
                        />
                    )}
                    {timesheet && timesheet.type === ETimesheetType.weekly && (
                        <CPicker
                            title={generalMessages.week}
                            value={timesheet.week || 0}
                            values={Array.from(
                                Array(
                                    weeksInYear(
                                        timesheet.year ||
                                            new Date().getFullYear(),
                                    ),
                                ),
                            ).map((_, i) => ({ value: i, label: `${i}` }))}
                            onChange={(v) =>
                                setTimesheet(
                                    (prev) =>
                                        new MTimesheet({
                                            ...prev,
                                            week: v,
                                        }),
                                )
                            }
                        />
                    )}
                </CCard>
            )}
            {!!shifts.length && (
                <CCard embedded={embedded}>
                    <View style={style.horizontalSplit}>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.date}
                            />
                        </View>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.from}
                            />
                        </View>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.to}
                            />
                        </View>
                        <View
                            style={[style.centeredItems, { width: cellWidth }]}
                        >
                            <CText
                                secondaryHeadline
                                message={timesheetMessages.breaks}
                            />
                        </View>
                    </View>
                </CCard>
            )}
            {shifts.map((shift, i) => (
                <CCard
                    key={i}
                    style={{ zIndex: 1 + shifts.length - i }}
                    outsideStyle={{ zIndex: 1 + shifts.length - i }}
                    hoverEffects={!timesheet.complete}
                >
                    <View style={[style.centeredContent, { zIndex: 1 }]}>
                        {editingIndex !== i ? (
                            <ShiftRow
                                shift={shift}
                                toggleEdit={
                                    !timesheet.complete
                                        ? () => setEditingIndex(i)
                                        : undefined
                                }
                                onLayout={(e) => {
                                    setCellWidth(
                                        e.nativeEvent.layout.width / 4,
                                    );
                                }}
                                cellWidth={cellWidth}
                            />
                        ) : (
                            <ShiftRowEdit
                                onChange={(next) => onChangeShift(i, next)}
                                onRemove={() => removeShift(i)}
                                shift={shift}
                                cellWidth={cellWidth}
                            />
                        )}
                    </View>
                </CCard>
            ))}
            {signOff ? (
                <View>
                    <View
                        style={[
                            style.verticalPadded,
                            { marginHorizontal: 'auto' },
                        ]}
                    >
                        <CButton
                            onPress={addShift}
                            title={timesheetMessages.addShift}
                            icon="plus"
                        />
                    </View>
                    <View style={[style.verticalPadded]}>
                        <Signature
                            text={capitalize(format(actionMessages.sign))}
                            onOK={handleSave}
                            okTitle={timesheetMessages.createSignedDocument}
                        />
                    </View>
                </View>
            ) : (
                <View
                    style={[style.verticalPadded, { marginHorizontal: 'auto' }]}
                >
                    <CButton
                        onPress={addShift}
                        title={timesheetMessages.addShift}
                        icon="plus"
                    />
                    <CButton onPress={handleSave} title={actionMessages.save} />
                    {isAgencyUser(userData) && (
                        <CButton
                            onPress={exposeTimesheet}
                            title={timesheetMessages.expose}
                        />
                    )}
                </View>
            )}
        </ScrollProvider>
    );
};
