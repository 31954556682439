import React, { FC, useEffect, useMemo } from 'react';
import { EField, EProfession } from '../../enums';
import { useFormat } from '../../utilities/intl';
import { generalMessages } from '../../utilities/messages';
import { CPicker } from '../elements';
import { pickerMessages } from './picker.messages';
import { View } from 'react-native';

interface IProfessionPickerProps {
    onChange: (next?: EProfession) => void;
    value?: EProfession;
    titleOverride?: string;
    cy?: string;
    optionsFilter?: string[];
    allowEmpty?: boolean;
    field?: EField;
    placeholder?: React.ReactNode;
    horizontal?: boolean;
}

export const ProfessionPicker: FC<IProfessionPickerProps> = ({
    value,
    onChange,
    cy,
    titleOverride,
    optionsFilter,
    allowEmpty,
    field,
    placeholder,
    horizontal,
}) => {
    const format = useFormat();
    /**
     * professions based on field
     */
    const professions = useMemo(() => {
        switch (field) {
            case EField.medic:
                return [EProfession.doctor, EProfession.nurse];
            case EField.education:
                return [EProfession.teacher, EProfession.socialWorker];
            default:
                return [EProfession.undefined];
        }
    }, [field]);
    /**
     * professions filtered and sorted
     */
    const filteredProfessions = useMemo(() => {
        const filter = optionsFilter || [];
        return professions
            .filter((v) => !filter.includes(v))
            .map((v) => {
                return {
                    label: format(generalMessages[v]),
                    value: v,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [optionsFilter, professions]);
    /**
     * effect to set profession if it is only one
     */
    useEffect(() => {
        if (professions.length === 1 && value !== professions[0]) {
            onChange(professions[0]);
        }
    }, [professions, value]);
    /**
     * return a empty view if there never where any contents to pick from
     */
    if (!Object.keys(professions).length) {
        return <View />;
    }
    /**
     * return placeholder if professions where filtered dry
     */
    if (!filteredProfessions.length) {
        return <>{placeholder}</>;
    }
    /**
     * return empty fragment if the only choice is undefined
     */
    if (professions.length === 1 && professions[0] === EProfession.undefined) {
        return <></>;
    }
    /**
     * render
     */
    return (
        <CPicker
            cy={cy || 'profession-picker'}
            title={titleOverride || format(pickerMessages.profession)}
            values={filteredProfessions}
            onChange={onChange}
            value={value ? value : ''}
            placeholder={`-${format(pickerMessages.professionPlaceholder)}-`}
            allowEmpty={allowEmpty}
            horizontal={horizontal}
        />
    );
};
