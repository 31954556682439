import { defineMessages } from 'react-intl';

const scope = 'fileInput.';

export const fileInputMessages = defineMessages({
    selectFileType: {
        id: scope + 'selectFileType',
        defaultMessage: 'Please select a file type',
    },
    selectFileTypeText: {
        id: scope + 'selectFileTypeText',
        defaultMessage:
            'Please select a file type, so the file you upload can be categorized.',
    },
    fileType: {
        id: scope + 'fileType',
        defaultMessage: 'File type',
    },
    fileTypePlaceholder: {
        id: scope + 'fileTypePlaceholder',
        defaultMessage: 'Select file type',
    },
    noFiles: {
        id: scope + 'noFiles',
        defaultMessage: 'No files uploaded yet.',
    },
    uploadAnotherFile: {
        id: scope + 'uploadAnotherFile',
        defaultMessage: 'Upload another file',
    },
});
