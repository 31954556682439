import React, { FC, useEffect, useState } from 'react';
import { MWorkplace } from '../../../models';
import { ECollections, EJobStatus } from '../../../enums';
import { useFireBase } from '../../../utilities/firebase';
import { isSuperUser } from '../../../utilities/auth';
import { IFilter } from '../../../utilities/firebase/store';
import { useStyle } from '../../../utilities/styles';
import { CButton } from '../../../components';
import { jobMessages } from '../../Job/job.messages';
import { useFormat } from '../../../utilities/intl';
/**
 * job application filter button with self aware length check
 */
export const JobApplicationFilterButton: FC<{
    workplaces?: MWorkplace[];
    status: EJobStatus;
    statusFilter?: EJobStatus;
    onPress: (status: EJobStatus) => void;
}> = ({ workplaces, status, statusFilter, onPress }) => {
    // global state
    const { userData, getDataIndex } = useFireBase();
    const style = useStyle();
    const format = useFormat();
    // local state
    const [length, setLength] = useState(0);
    /**
     * effect to load current length of query result with this status as filter
     */
    useEffect(() => {
        if (
            !workplaces ||
            !workplaces.length ||
            status === EJobStatus.archived
        ) {
            setLength(0);
            return;
        }

        const filter: IFilter[] = [];
        if (workplaces.length) {
            filter.push({
                field: 'workPlaceId',
                operator: 'in',
                value: workplaces.map((wp) => wp.documentId),
            });
        } else if (!isSuperUser(userData)) {
            return;
        }
        filter.push({ field: 'status', value: status });
        getDataIndex(ECollections.jobs, {
            orderBy: 'createdOn',
            filter,
            getLength: true,
        }).then((result) => {
            if (result && !isNaN(result as number)) {
                setLength(result as number);
            } else {
                setLength(0);
            }
        });
    }, [userData, workplaces, status]);
    /**
     * render
     */
    return (
        <CButton
            key={status}
            minor={statusFilter !== status}
            small
            title={
                status === EJobStatus.archived
                    ? jobMessages.archived
                    : `${format(jobMessages[status])} (${length})`
            }
            onPress={() => {
                onPress(status);
            }}
            style={style.smallMargin}
        />
    );
};
