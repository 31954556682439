import { useState, useEffect, useMemo } from 'react';
import { Dimensions, Platform, ScaledSize } from 'react-native';
import { useSafeAreaFrame } from 'react-native-safe-area-context';
import { HEADERHEIGHT, MOBILEWIDTH } from '../constants';
import { useLocation } from '../routing';
/**
 * hook to get the dimensions of the screen and window
 * @returns screen and window dimensions isLandscape and windowDimensions as default width and height
 */
export const useDimensions = () => {
    const [screenData, setScreenData] = useState<ScaledSize>(
        Dimensions.get('screen'),
    );
    const [windowData, setWindowData] = useState<ScaledSize>(
        Dimensions.get('window'),
    );
    const { height: safeAreaFrameHeight } = useSafeAreaFrame();
    const location = useLocation();
    /**
     * show head instead of side menu on small screens and during onboarding
     */
    const showHead = useMemo(() => {
        return (
            windowData.width < MOBILEWIDTH ||
            location.pathname === '/onboarding'
        );
    }, [windowData, location]);
    /**
     * memoized check for landscape display
     */
    const isLandscape = useMemo(() => {
        windowData.width > windowData.height;
    }, [windowData]);
    /**
     * fixed height for wrapper adjusted for ios status bar && safeArea frame
     */
    const wrapperHeight = useMemo(() => {
        // const isIos = Platform.OS === 'ios' && !isLandscape;
        // const value = isIos ? insets.top : 0;
        return Platform.OS === 'web' ? windowData.height : safeAreaFrameHeight;
    }, [windowData, safeAreaFrameHeight]);
    /**
     * fixed height for main pane adjusted for header
     */
    const mainHeight = useMemo(() => {
        const value = showHead ? HEADERHEIGHT + 40 : 0;
        return wrapperHeight - value;
    }, [showHead, wrapperHeight]);
    /**
     * effect to add event listener to dimensions to update state on change
     */
    useEffect(() => {
        const onChange = (result: {
            window: ScaledSize;
            screen: ScaledSize;
        }) => {
            setScreenData(result.screen);
            setWindowData(result.window);
        };

        const listener = Dimensions.addEventListener('change', onChange);

        return () => listener.remove();
    }, []);
    /**
     * return data
     */
    return {
        screen: screenData,
        window: windowData,
        isLandscape,
        showHead,
        mainHeight,
        wrapperHeight,
        ...windowData,
    };
};
