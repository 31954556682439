import React, { FC, useCallback } from 'react';
import { IShiftRowProps } from './ShiftRow';
import { useStyle, useTheme } from '../../../utilities/styles';
import { View } from 'react-native';
import { CText, CDatePicker, CButton, CTextInput } from '../../../components';
import { CTimePicker } from '../../../components/elements/CTimePicker/CTimePicker';
import { MFromTo } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { hour, minute, timeString } from '../../../utilities/functions';
import { timesheetMessages } from '../timesheet.messages';
import { useDialog } from '../../../utilities/dialog';
import { actionMessages } from '../../../utilities/messages';

export const ShiftRowEdit: FC<IShiftRowProps> = ({
    shift,
    onChange,
    onRemove,
    cellWidth,
}) => {
    const style = useStyle();
    const dialog = useDialog();
    const { userAgencies } = useFireBase();
    const { theme } = useTheme();
    /**
     * callback to add a break
     */
    const addBreak = useCallback(async () => {
        if (!onChange) {
            return;
        }
        const bFrom = new Date((shift.from + shift.to) / 2);
        const cBT = userAgencies.length
            ? bFrom.getTime() + userAgencies[0].breakLength * minute
            : bFrom.getTime() + hour;
        const bTo = new Date(cBT < shift.to ? cBT : shift.to);
        await dialog({
            title: timesheetMessages.addBreak,
            timeInputs: [
                {
                    id: 'from',
                    title: timesheetMessages.from,
                    upperLimit: shift.to,
                    lowerLimit: shift.from,
                    defaultValue: bFrom.getTime(),
                },
                {
                    id: 'to',
                    title: timesheetMessages.to,
                    upperLimit: shift.to,
                    lowerLimit: shift.from,
                    defaultValue: bTo.getTime(),
                },
            ],
            buttons: [
                {
                    text: timesheetMessages.addBreak,
                    onPress: (inputs) => {
                        const inputFrom = inputs?.find(
                            (i) => i.id === 'from',
                        )?.value;
                        const inputTo = inputs?.find(
                            (i) => i.id === 'to',
                        )?.value;
                        if (!inputFrom || !inputTo) {
                            return;
                        }
                        const inputFromDate = new Date(inputFrom);
                        const inputToDate = new Date(inputTo);
                        const fromHour = inputFromDate.getHours();
                        const fromMinute = inputFromDate.getMinutes();
                        const toHour = inputToDate.getHours();
                        const toMinute = inputToDate.getMinutes();
                        onChange({
                            breaks: [
                                ...shift.breaks,
                                new MFromTo({
                                    from: bFrom.getTime(),
                                    fromHour,
                                    fromMinute,
                                    to: bTo.getTime(),
                                    toHour,
                                    toMinute,
                                }),
                            ],
                        });
                    },
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });
    }, [onChange, shift]);
    /**
     * remove break callback
     */
    const removeBreak = useCallback(
        (index: number) => {
            if (!onChange) return;
            const nextBreaks = [...shift.breaks];
            nextBreaks.splice(index, 1);
            onChange({ breaks: nextBreaks });
        },
        [shift, onChange],
    );
    /**
     * render
     */
    return (
        <View>
            <View style={[style.horizontalSplit, style.centeredItems]}>
                <View
                    style={[
                        style.horizontal,
                        style.centeredItems,
                        style.centeredContent,
                        { width: cellWidth, zIndex: 1 },
                    ]}
                >
                    <CDatePicker
                        value={
                            new Date(+shift.year, +shift.month - 1, +shift.date)
                        }
                        onChange={(d) => {
                            if (!onChange) return;
                            onChange({
                                year: `${d.getFullYear()}`,
                                month: `${d.getMonth() + 1}`,
                                date: `${d.getDate()}`,
                            });
                        }}
                    />
                </View>
                <View
                    style={[
                        style.horizontal,
                        style.centeredContent,
                        style.centeredItems,
                        { width: cellWidth },
                    ]}
                >
                    <CTimePicker
                        onChange={(e) => {
                            if (!onChange) return;
                            onChange({
                                fromHour: e.getHours(),
                                fromMinute: e.getMinutes(),
                            });
                        }}
                        value={
                            new Date(
                                +shift.year,
                                +shift.month - 1,
                                +shift.date,
                                shift.fromHour,
                                shift.fromMinute,
                            )
                        }
                        minDate={new Date(
                            +shift.year,
                            +shift.month - 1,
                            +shift.date,
                            0,
                            0,
                        ).getTime()}
                        maxDate={new Date(
                            +shift.year,
                            +shift.month - 1,
                            +shift.date,
                            shift.toHour,
                            shift.toMinute,
                        ).getTime()}
                    />
                </View>
                <View
                    style={[
                        style.horizontal,
                        style.centeredContent,
                        style.centeredItems,
                        { width: cellWidth },
                    ]}
                >
                    <CTimePicker
                        onChange={(e) => {
                            if (!onChange) return;
                            onChange({
                                toHour: e.getHours(),
                                toMinute: e.getMinutes(),
                            });
                        }}
                        value={
                            new Date(
                                +shift.year,
                                +shift.month - 1,
                                +shift.date,
                                shift.toHour,
                                shift.toMinute,
                            )
                        }
                        minDate={new Date(
                            +shift.year,
                            +shift.month - 1,
                            +shift.date,
                            shift.fromHour,
                            shift.fromMinute,
                        ).getTime()}
                        maxDate={new Date(
                            +shift.year,
                            +shift.month - 1,
                            +shift.date,
                            24,
                            0,
                        ).getTime()}
                    />
                </View>
                <View
                    style={[
                        style.centeredContent,
                        style.centeredItems,
                        { width: cellWidth },
                    ]}
                >
                    {shift.breaks.map((b, i) => (
                        <View key={i} style={style.horizontal}>
                            <CText
                                message={`${timeString(
                                    b.fromHour,
                                    b.fromMinute,
                                )} - ${timeString(b.toHour, b.toMinute)}`}
                            />
                            <CButton
                                onPress={() => removeBreak(i)}
                                icon="close"
                                smallest
                                transparent
                                minor
                                iconColor={theme.errorColor}
                            />
                        </View>
                    ))}
                    <CButton
                        onPress={() => {
                            addBreak();
                        }}
                        icon="plus"
                        smallest
                        transparent
                        minor
                        title={timesheetMessages.addBreak}
                    />
                </View>
            </View>
            {onChange && (
                <CTextInput
                    autoExtend
                    value={shift.note}
                    onChangeText={(note) => onChange({ note })}
                    label={timesheetMessages.note}
                />
            )}
            <View style={style.horizontalSpaced}>
                {onRemove && (
                    <CButton
                        onPress={onRemove}
                        icon="close"
                        smallest
                        transparent
                        minor
                        iconColor={theme.errorColor}
                        title={timesheetMessages.deleteRow}
                        fontStyles={{ color: theme.errorColor }}
                    />
                )}
            </View>
        </View>
    );
};
