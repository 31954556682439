import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    MAgency,
    MAvailability,
    MProfessionalInfo,
    MProfessionalProfile,
} from '../../../../models';
import { useFireBase } from '../../../../utilities/firebase';
import { EApplicationStatus, ECollections, EUserType } from '../../../../enums';
import { useStyle, useTheme } from '../../../../utilities/styles';
import {
    CButton,
    CCard,
    CIcon,
    CText,
    ProfilePicture,
} from '../../../../components';
import { useFormat } from '../../../../utilities/intl';
import { educationMessages } from '../../../../utilities/messages';
import { profileMessages } from '../../../Profile/Profile.messages';
import { profProfileMessages } from '../../../ProfProfile/ProfProfile.messages';
import {
    convertNumberToCurrencyString,
    convertWeekAndYearToDate,
    day,
    week,
} from '../../../../utilities/functions';
import { useEnvironment } from '../../../../utilities/contexts';
import { availabilityMessages } from '../../availability.messages';
import { useNavigate } from 'react-router-native';
import { dayMessages } from '../../../../utilities/messages';

export const AvailableUserRow: FC<{
    availability: MAvailability;
    unavailabilities: MAvailability[];
}> = ({ availability, unavailabilities }) => {
    const { getDataById, getDataIndex, userWorkplaces } = useFireBase();
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const navigate = useNavigate();
    const { region } = useEnvironment();

    const [resource, setResource] = useState<MProfessionalInfo>();
    const [agency, setAgency] = useState<MAgency>();
    const [wasRequested, setWasRequested] = useState(false);
    const [requestWasRejected, setWasRejected] = useState(false);
    /**
     * style for views
     */
    const viewStyle = useMemo(() => {
        return { flex: 1, justifyContent: 'center' as const };
    }, []);
    /**
     * overlapping unavailabilities
     */
    const unactiveDays = useMemo(() => {
        const next: string[] = [];
        unavailabilities
            .filter(
                (a) =>
                    a.uid === availability.uid ||
                    a.profileId === availability.profileId,
            )
            .filter((a) => {
                const overlap = a.activeDates.filter((s) =>
                    availability.activeDates.includes(s),
                );

                next.push(...overlap);

                return overlap.length;
            });

        return Array.from(new Set(next));
    }, [availability, unavailabilities]);
    /**
     * days translated
     */
    const days = useMemo(() => {
        return Object.keys(dayMessages)
            .filter((v) => !v.includes('Short'))
            .map((key) => format(dayMessages[key as keyof typeof dayMessages]));
    }, []);
    /**
     * memoized hourly rate with double digit fix applied
     */
    const hourlyRate = useMemo(() => {
        const talentProfile = resource as MProfessionalProfile;
        if (!talentProfile || !talentProfile.wage) return 0;
        const next = talentProfile.wage.wage + talentProfile.wage.regularWage;
        /**
         * this addition can fuck up so fix it
         */
        return Math.round(next * 100) / 100;
    }, [resource]);
    /**
     * memoized from to
     */
    const { from, to } = useMemo(() => {
        const from = convertWeekAndYearToDate(
            availability.year,
            availability.start,
        );
        const to = new Date(
            from.getTime() + (availability.repeatCount + 1) * week - day,
        );

        return { from, to };
    }, [availability]);
    /**
     * effect to load resource
     */
    useEffect(() => {
        if (availability.profileId) {
            getDataById(
                ECollections.publicProfProfiles,
                availability.profileId,
            ).then((r) => setResource(new MProfessionalInfo(r)));
        } else {
            getDataById(ECollections.publicUsers, availability.uid).then((r) =>
                setResource(new MProfessionalInfo({ ...r })),
            );
        }
        if (availability.agencyId) {
            getDataById(
                ECollections.publicAgencies,
                availability.agencyId,
            ).then((r) => setAgency(new MAgency(r)));
        }
    }, [availability]);
    /**
     * effect to load request
     */
    useEffect(() => {
        for (const wp of userWorkplaces) {
            const filter = [{ field: 'workplaceId', value: wp.documentId }];
            if (availability.profileId) {
                filter.push({
                    field: 'profileId',
                    value: availability.profileId,
                });
            } else {
                filter.push({ field: 'userId', value: availability.uid });
            }
            getDataIndex(ECollections.requests, { filter }).then((r1) => {
                if ((r1 as any[]).length) {
                    setWasRequested(true);
                    getDataIndex(ECollections.requests, {
                        filter: [
                            ...filter,
                            {
                                field: 'status',
                                value: EApplicationStatus.rejected,
                            },
                        ],
                    }).then((r2) => {
                        if ((r2 as any[]).length === (r1 as any[]).length) {
                            setWasRejected(true);
                        }
                    });
                }
            });
        }
    }, [userWorkplaces, availability]);

    return (
        <CCard
            hoverEffects
            cy={'open-user-' + resource?.lastName}
            onPress={
                wasRequested
                    ? undefined
                    : () =>
                          navigate(
                              '/availableUsers/request/' +
                                  availability.documentId,
                          )
            }
        >
            <View style={style.horizontal}>
                {!!resource && (
                    <ProfilePicture
                        data={{ ...resource, type: EUserType.talent }}
                        small
                    />
                )}
                <View style={[style.flex1, style.leftPadded]}>
                    {agency && (
                        <CText
                            secondaryHeadline
                            style={{ marginHorizontal: 5 }}
                        >
                            {agency.name}
                        </CText>
                    )}
                    {!!resource?.educations.length && (
                        <View
                            style={[style.horizontalWrap, style.centeredItems]}
                        >
                            <CIcon
                                icon="school"
                                size={16}
                                tint={theme.textSecondaryColor}
                            />
                            <CText style={style.leftPadded}>
                                {`${resource.educations
                                    .map((edu) =>
                                        format(
                                            educationMessages[
                                                edu as keyof typeof educationMessages
                                            ],
                                        ),
                                    )
                                    .join(', ')}`}
                            </CText>
                        </View>
                    )}
                    {!!resource?.yearsOfExperience && (
                        <CText>
                            {`${resource.yearsOfExperience} ${format(
                                profileMessages.yoe,
                            )}`}
                        </CText>
                    )}
                    {hourlyRate ? (
                        <CText>
                            {`${format(
                                profProfileMessages.rate,
                            )} ${convertNumberToCurrencyString(
                                hourlyRate,
                                region,
                            )}`}
                        </CText>
                    ) : (
                        <CText message={profProfileMessages.noRate} />
                    )}
                    <View style={[style.horizontalSplit]}>
                        <View style={viewStyle}>
                            <CText>
                                {format(availabilityMessages.availableFromTo, {
                                    fromDate: from.toLocaleDateString('de'),
                                    toDate: to.toLocaleDateString('de'),
                                })}
                            </CText>
                            {/* {availability.repeat && (
                                <View style={viewStyle}>
                                    <CText
                                        message={format(
                                            availabilityMessages.repeats,
                                            {
                                                x: availability.repeatCount,
                                            },
                                        )}
                                    />
                                </View>
                            )} */}
                        </View>
                        {/* <View style={viewStyle}>
                            <CText>{availability.year} </CText>
                        </View> */}
                        {/* <View style={viewStyle}>
                            <CText>{availability.note}</CText>
                        </View> */}
                        <View style={viewStyle}>
                            {availability.days.map((d, i) => (
                                <View key={i} style={style.horizontal}>
                                    <CText style={style.flex1}>{`${
                                        days[d.day - 1]
                                    }`}</CText>
                                    <CText style={style.flex1}>{`${
                                        d.inheriting
                                            ? `${availability.from} - ${availability.to}`
                                            : `${d.from} - ${d.to}`
                                    }`}</CText>
                                </View>
                            ))}
                        </View>
                    </View>
                    {!!unactiveDays.length && (
                        <>
                            <View style={style.verticalPadded} />
                            <CText
                                message={availabilityMessages.notAvailableOn}
                            />
                            <CText message={unactiveDays.join(' ')} />
                        </>
                    )}
                </View>
                {wasRequested ? (
                    requestWasRejected ? (
                        <View>
                            <CText message={availabilityMessages.wasRejected} />
                            <CButton
                                title={availabilityMessages.newRequest}
                                onPress={() =>
                                    navigate(
                                        '/availableUsers/request/' +
                                            availability.documentId,
                                    )
                                }
                                small
                            />
                        </View>
                    ) : (
                        <CText
                            message={availabilityMessages.alreadyRequested}
                            style={style.verticalPadded}
                        />
                    )
                ) : (
                    <CButton
                        onPress={() =>
                            navigate(
                                '/availableUsers/request/' +
                                    availability.documentId,
                            )
                        }
                        title={availabilityMessages.request}
                    />
                )}
            </View>
        </CCard>
    );
};
