import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCheckBox,
    CText,
    CTextInput,
    TouchableView,
} from '../../../components';
import { CDatePicker } from '../../../components';
import { ECollections, EUserType } from '../../../enums';
import { IDayAvailability, MAvailability, MUserData } from '../../../models';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { actionMessages } from '../../../utilities/messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import {
    convertWeekAndYearToDate,
    day,
    getWeekNumber,
    week,
} from '../../../utilities/functions';
import { useDimensions } from '../../../utilities/hooks/useDimensions';
import { calendarMessages } from '../Calendar.messages';
import { useSearchParams, useSecureNavigate } from '../../../utilities/routing';
import { IEditAvailabilityProps } from './EditAvailability';
import { DayAvailability2 } from './DayAvailability.v2';
import { AllDayAvailability } from './AllDayAvailability';
import { isSuperUser } from '../../../utilities/auth';
import { ScrollContext } from '../../../utilities/contexts';
import { dayMessages } from '../../../utilities/messages/day.messages';
/**
 * if availability edit was so good. why is there no availability edit 2
 */
export const EditAvailability2: FC<IEditAvailabilityProps> = ({
    close,
    prev,
    startDate,
    startHour,
    returnToProfile,
    selectedProfile,
}) => {
    // global state
    const style = useStyle();
    const dialog = useDialog();
    const { theme } = useTheme();
    const { width } = useDimensions();
    const { secureNavigate } = useSecureNavigate();
    const [searchParams] = useSearchParams();
    const format = useFormat();
    const { post, put, userData, remove, getDataById } = useFireBase();
    const { setY, maxScrollY } = useContext(ScrollContext);
    // local state
    const [currentDate, setCurrentDate] = useState(new Date());
    const [availability, setAvailability] = useState(
        new MAvailability({
            year: new Date().getFullYear(),
            start: getWeekNumber(new Date()),
            exactEndTime: new Date().getTime() + week,
            exactStartTime: new Date().getTime(),
        }),
    );
    const [mountedOn, setMountedOn] = useState(0);
    const [tracker, setTracker] = useState(0);
    /**
     * memoized from to
     */
    const { from, to } = useMemo(() => {
        const from = availability.exactStartTime
            ? new Date(availability.exactStartTime)
            : convertWeekAndYearToDate(availability.year, availability.start);
        const to = availability.exactEndTime
            ? new Date(availability.exactEndTime)
            : new Date(
                  from.getTime() + (availability.repeatCount + 1) * week - day,
              );

        return { from, to };
    }, [availability]);
    /**
     * days translated
     */
    const days = useMemo(() => {
        return Object.keys(dayMessages)
            .filter((v) => !v.includes('Short'))
            .map((key) => format(dayMessages[key as keyof typeof dayMessages]));
    }, []);
    /**
     * handle date change callback
     */
    const handleDateChange = useCallback((d: Date) => {
        const w = getWeekNumber(d);
        const y = d.getFullYear();
        setCurrentDate(d);
        setAvailability((prev) => {
            return { ...prev, year: y, start: w, exactStartTime: d.getTime() };
        });
    }, []);
    /**
     * callback to get repeat count from selected date
     */
    const handleToChange = useCallback((d: Date) => {
        setAvailability((prev) => {
            const from = convertWeekAndYearToDate(
                availability.year,
                availability.start,
            );
            const diff = d.getTime() - from.getTime();
            const repeatCount = Math.floor(diff / week);

            return {
                ...prev,
                repeatCount,
                repeat: !!repeatCount,
                exactEndTime: d.getTime(),
            };
        });
    }, []);
    /**
     * callback to change day value
     */
    const handleDayChange = useCallback(
        (dayNumber: number, day?: IDayAvailability) => {
            setAvailability((prev) => {
                const prevIndex = prev.days.findIndex(
                    (d) => d.day === dayNumber,
                );
                const next = [...prev.days];
                if (prevIndex >= 0) {
                    if (day) {
                        next.splice(prevIndex, 1, day);
                    } else {
                        next.splice(prevIndex, 1);
                    }
                } else if (day) {
                    next.push(day);
                }
                return { ...prev, days: next };
            });
        },
        [],
    );
    /**
     * callback to set everyday to inheriting
     */
    const setInheriting = useCallback(
        (excludeWeekend = false) =>
            setAvailability((prev) => ({
                ...prev,
                days: days
                    .filter((_, i) => !excludeWeekend || i < 5)
                    .map((_, i) => {
                        const prevDay = prev.days.find((d) => d.day === i + 1);
                        return prevDay
                            ? { ...prevDay, inheriting: true }
                            : {
                                  day: i + 1,
                                  inheriting: true,
                                  from: 0,
                                  to: 0,
                              };
                    }),
            })),
        [days],
    );
    /**
     * save callback
     */
    const handleSave = useCallback(async () => {
        const next = new MAvailability({
            ...availability,
            uid: userData.documentId,
            profileId: selectedProfile?.documentId,
            agencyId: selectedProfile?.agencyId,
            field: selectedProfile?.field,
            educations: selectedProfile?.educations,
            profession: selectedProfile?.profession,
            region: selectedProfile?.region,
            // clear tracking params to issue recalculation
            activeDates: undefined,
            activeMonths: undefined,
        });
        const paramId = searchParams.get('uid');

        if (paramId && isSuperUser(userData)) {
            const r = await getDataById(ECollections.users, paramId);
            const ud = new MUserData(r);
            next.uid = ud.documentId;
            next.field = ud.field;
            next.educations = ud.educations;
            next.profession = ud.profession;
            next.region = ud.region;
        } else if (userData.type === EUserType.user) {
            next.field = userData.field;
            next.educations = userData.educations;
            next.profession = userData.profession;
            next.region = userData.region;
        }

        if (next.documentId) {
            await put(
                ECollections.availabilities,
                availability.documentId,
                next,
            );
        } else {
            await post(ECollections.availabilities, next);
        }

        close();
    }, [userData, availability, selectedProfile]);
    /**
     * effect to init availability with prev
     */
    useEffect(() => {
        if (prev) {
            setAvailability(new MAvailability(prev));
            setCurrentDate(convertWeekAndYearToDate(prev.year, prev.start));
        } else if (startDate) {
            const selectedDate = new Date(startDate);
            handleDateChange(selectedDate);
            setAvailability((prev) => {
                if (startHour !== undefined) {
                    prev.from = startHour;
                    prev.to = startHour + 1;
                }
                const preselectedDay = {
                    day: selectedDate.getDay(),
                    from: prev.from,
                    to: prev.to,
                    inheriting: false,
                };
                prev.days.push(preselectedDay);

                const year = selectedDate.getFullYear();
                const start = getWeekNumber(selectedDate);
                const weekStartDate = convertWeekAndYearToDate(year, start);

                return new MAvailability({
                    ...prev,
                    year,
                    start,
                    exactEndTime: weekStartDate.getTime() + week,
                    exactStartTime: weekStartDate.getTime(),
                });
            });
        }
        setMountedOn(Date.now());
    }, [prev, startDate, startHour, handleDateChange]);
    /**
     * effect to scroll to end after creation
     * TODO: non dirty solution without timeout please
     */
    useEffect(() => {
        if (mountedOn > tracker) {
            const t = setTimeout(() => {
                setTracker(mountedOn);
                setY(maxScrollY);
            }, 100);
            return () => clearTimeout(t);
        }
    }, [mountedOn, tracker, maxScrollY, setY]);
    /**
     * render
     */
    return (
        <View
            style={[
                style.card,
                {
                    maxWidth: width - 20,
                },
            ]}
        >
            <CText style={style.verticalPadded} bold headline>
                {availability.documentId
                    ? format(calendarMessages.editAvailability)
                    : format(calendarMessages.addAvailability)}
            </CText>
            {returnToProfile && (
                <View style={style.horizontal}>
                    <CButton
                        small
                        icon={'chevronLeft'}
                        title={format(calendarMessages.returnToProfile)}
                        onPress={() => {
                            secureNavigate(-1);
                        }}
                    />
                </View>
            )}
            <View style={style.verticalPadded}>
                <View
                    style={[
                        style.horizontal,
                        style.horizontalWrap,
                        { alignItems: 'center' },
                    ]}
                >
                    {/* <CText style={style.horizontalPadded}>
                        {format(calendarMessages.start)}
                    </CText>
                    <CDatePicker
                        value={currentDate}
                        minDate={new Date().getTime()}
                        onChange={handleDateChange}
                    /> */}
                    <CDatePicker
                        title={calendarMessages.fromWeek}
                        value={from}
                        minDate={new Date().getTime()}
                        onChange={handleDateChange}
                        horizontal
                    />
                    <CDatePicker
                        title={calendarMessages.toWeek}
                        value={to}
                        minDate={from.getTime()}
                        onChange={handleToChange}
                        horizontal
                    />
                    <CTextInput
                        horizontal
                        label={format(calendarMessages.note)}
                        placeholder={format(calendarMessages.notePlaceholder)}
                        value={availability.note}
                        onChangeText={(t) => {
                            setAvailability((prev) => {
                                prev.note = t;
                                return { ...prev };
                            });
                        }}
                        autoExtend
                    />
                </View>
                <CCheckBox
                    checked={availability.negate}
                    onCheckedChanged={(negate) =>
                        setAvailability((prev) => {
                            return { ...prev, negate };
                        })
                    }
                    title={calendarMessages.isUnavailability}
                />
            </View>
            {availability.days.length &&
            !availability.days.find((d) => !d.inheriting) ? (
                <View style={{ zIndex: 1 }}>
                    <AllDayAvailability
                        availability={availability}
                        onChange={setAvailability}
                        excludeWeekend={availability.days.length === 5}
                    />
                </View>
            ) : (
                <View style={{ zIndex: 1 }}>
                    <TouchableView
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            { paddingVertical: 10 },
                        ]}
                        onPress={() => setInheriting()}
                    >
                        <CCheckBox
                            checked={false}
                            onCheckedChanged={() => setInheriting()}
                        />
                        <CText
                            secondaryHeadline
                            style={style.leftPadded}
                            message={calendarMessages.everyDay}
                        />
                    </TouchableView>
                    <TouchableView
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            { paddingVertical: 10 },
                        ]}
                        onPress={() => setInheriting(true)}
                    >
                        <CCheckBox
                            checked={false}
                            onCheckedChanged={() => setInheriting(true)}
                        />
                        <CText
                            secondaryHeadline
                            style={style.leftPadded}
                            message={calendarMessages.everyWeekDay}
                        />
                    </TouchableView>
                    {days.map((day, i) => {
                        const dayValues = availability.days.find(
                            (d) => d.day === i + 1,
                        );
                        const startDate = new Date(
                            convertWeekAndYearToDate(
                                availability.year,
                                availability.start,
                            ),
                        );
                        return (
                            <View
                                style={{ zIndex: days.length + 1 - i }}
                                key={day}
                            >
                                <DayAvailability2
                                    currentDate={currentDate}
                                    day={day}
                                    dayNumber={i + 1}
                                    dayValues={dayValues}
                                    availability={availability}
                                    onChangeDay={(day) =>
                                        handleDayChange(i + 1, day)
                                    }
                                    startDate={startDate}
                                    startHour={startHour}
                                />
                            </View>
                        );
                    })}
                </View>
            )}
            <View
                style={[
                    style.horizontalSplit,
                    style.verticalHeavyPadded,
                    style.centeredItems,
                ]}
            >
                <View />
            </View>
            <View
                style={[
                    style.horizontalSplit,
                    style.horizontalWrap,
                    style.verticalHeavyPadded,
                ]}
            >
                {returnToProfile && (
                    <View style={style.horizontal}>
                        <CButton
                            icon={'chevronLeft'}
                            title={format(calendarMessages.returnToProfile)}
                            onPress={() => {
                                secureNavigate(-1);
                            }}
                        />
                    </View>
                )}
                <CButton
                    title={format(actionMessages.cancel)}
                    onPress={close}
                    warning
                />
                {prev && (
                    <CButton
                        title={format(actionMessages.delete)}
                        onPress={() => {
                            dialog({
                                title: format(calendarMessages.confirmDelete),
                                message: format(
                                    calendarMessages.confirmDeleteText,
                                ),
                                buttons: [
                                    {
                                        text: format(actionMessages.delete),
                                        color: theme.errorColor,
                                    },
                                ],
                                cancelButton: {
                                    text: format(actionMessages.cancel),
                                },
                            }).then((confirmed) => {
                                if (confirmed) {
                                    remove(
                                        ECollections.availabilities,
                                        availability.documentId,
                                    ).then(() => {
                                        close();
                                    });
                                }
                            });
                        }}
                        danger
                    />
                )}
                <CButton
                    title={format(actionMessages.save)}
                    onPress={handleSave}
                    style={{ backgroundColor: theme.successColor }}
                />
            </View>
        </View>
    );
};
