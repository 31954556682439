import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { TouchableView, CText } from '../../../components';
import { useStyle, useTheme } from '../../../utilities/styles';
import { MContract } from '../../../models';
import { useFormat } from '../../../utilities/intl';
import { EContractStatus, EApplicationType, EProfession } from '../../../enums';
import { isPeasant } from '../../../utilities/auth';
import {
    generalMessages,
    fieldMessages,
    educationMessages,
} from '../../../utilities/messages';
import { contractMessages } from '../../Contract/contract.messages';
import { useFireBase } from '../../../utilities/firebase';

export const ContractColumnRow: FC<{
    i: number;
    contract: MContract;
    selectedContract?: MContract;
    setContract: (c: MContract) => void;
}> = ({ i, contract, selectedContract, setContract }) => {
    const { userData } = useFireBase();
    const style = useStyle();
    const format = useFormat();
    const { theme } = useTheme();
    const isContract = useMemo(
        () =>
            [EContractStatus.signed, EContractStatus.completed].includes(
                contract.status,
            ),
        [contract],
    );
    /**
     * schema copied from ../../Contract/components/ContractTitle.tsx
     */
    const contractTitle = useMemo(() => {
        return contract.type === EApplicationType.default
            ? !!contract.title
                ? `${format(contractMessages.unmanagedContract)} - ${
                      contract.title
                  }`
                : format(contractMessages.unmanagedContract)
            : isPeasant(userData) || !contract.title
            ? contract.education &&
              format(
                  isContract
                      ? contractMessages.contractForJobInX
                      : contractMessages.negotiationForJobInX,

                  {
                      x: [
                          contract.profession &&
                          contract.profession !== EProfession.undefined
                              ? format(generalMessages[contract.profession])
                              : contract.field
                              ? format(fieldMessages[contract.field])
                              : '',
                          format(
                              educationMessages[
                                  contract.education as keyof typeof educationMessages
                              ],
                          ),
                      ]
                          .filter((v) => !!v && v !== 'undefined')
                          .join(' - '),
                  },
              )
            : format(
                  isContract
                      ? contractMessages.contractForCustomTitle
                      : contractMessages.negotiationForCustomTitle,
                  { x: contract.title },
              );
    }, [contract, userData]);
    // TODO: from to classification
    // const adjustedFrom = today.from;
    const first = 0 === i;
    const last = 24 === i + 1;
    const marked = true;
    const i0 = i === 0;
    const i24 = i === 23;

    return (
        <View key={i} style={style.horizontal}>
            <TouchableView
                style={[
                    style.horizontal,
                    {
                        height: 25,
                        flex: 1,
                        borderWidth: 1,
                        borderColor: 'transparent',
                        borderBottomColor: theme.borderColor,
                        backgroundColor: marked
                            ? theme.warningColor
                            : undefined,
                    },
                    i0 && {
                        borderTopColor: theme.borderColor,
                    },
                    i24 && {
                        borderBottomColor: theme.borderColor,
                    },
                    marked && {
                        borderColor: 'transparent',
                        borderBottomColor: 'transparent',
                    },
                    first && {
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                    },
                    last && {
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                    },
                ]}
                onPress={() => {
                    if (contract.documentId !== selectedContract?.documentId)
                        setContract(contract);
                }}
            >
                {first && (
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CText>{contractTitle}</CText>
                    </View>
                )}
            </TouchableView>
        </View>
    );
};
