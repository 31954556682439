import AsyncStorage from '@react-native-async-storage/async-storage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-native';
import {
    EApplicationType,
    ECollections,
    EContractStatus,
    EJobStatus,
} from '../../../enums';
import {
    MApplication,
    MChat,
    MContract,
    MJob,
    MRequest,
    MReview,
    MTimesheet,
} from '../../../models';
import { isAgencyUser, isEmployer } from '../../../utilities/auth';
import { useDialog } from '../../../utilities/dialog';
import { useFireBase } from '../../../utilities/firebase';
import { useLock } from '../../../utilities/hooks/useLock';
import { contractMessages } from '../contract.messages';
import { usePostContractFile } from './functions/usePostContractFile';
import { actionMessages } from '../../../utilities/messages';
import { usePreContractFile } from './functions/usePreContractFile';
import { useTheme } from '../../../utilities/styles';
import { useFormat } from '../../../utilities/intl';
/**
 * state for contract view
 * @returns
 */
export const useContractViewState = () => {
    // global state
    const { id } = useParams<{ id: string }>();
    const dialog = useDialog();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const format = useFormat();
    const { lock } = useLock();
    const { getDataById, getDataIndex, userData, callFunction } = useFireBase();
    // local state
    const [contract, setContract] = useState<MContract>();
    const [request, setRequest] = useState<MRequest>();
    const [chat, setChat] = useState<MChat>();
    const [reload, setReload] = useState(Date.now());
    const [timesheetComplete, setTimesheetComplete] = useState(false);
    const [job, setJob] = useState<MJob>();
    const [application, setApplication] = useState<MApplication>();
    /**
     * memoized hours per week
     */
    const hpw = useMemo(() => {
        if (!contract) return 0;
        return contract.days.reduce((acc, d) => {
            return acc + (d.to - d.from);
        }, 0);
    }, [contract]);
    /**
     * callback to handle review values
     * - creates workplace review if user is not workplace user
     * - creates user review if contract is not agency
     * - creates agency & profile review if contract is agency
     */
    const handleReview = useCallback(
        async (inputs?: any[]) => {
            if (!contract || !inputs) return;
            const reviews = [];
            if (!isEmployer(userData)) {
                const workplaceReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.workplaces,
                    targetId: contract.workplaceId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter(
                            (i) =>
                                i.sectionId === 'employer' &&
                                i.id !== 'reviewText',
                        )
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text:
                        inputs.find(
                            (i) =>
                                i.sectionId === 'employer' &&
                                i.id === 'reviewText',
                        )?.value || '',
                });
                reviews.push(workplaceReview);
            } else if (contract.agencyId && contract.profileId) {
                const agencyReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.agencies,
                    targetId: contract.agencyId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter(
                            (i) =>
                                i.sectionId === 'agency' &&
                                i.id !== 'reviewText',
                        )
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text:
                        inputs.find(
                            (i) =>
                                i.sectionId === 'agency' &&
                                i.id === 'reviewText',
                        )?.value || '',
                });
                const profileReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.profProfiles,
                    targetId: contract.profileId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter(
                            (i) =>
                                i.sectionId === 'employee' &&
                                i.id !== 'reviewText',
                        )
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text:
                        inputs.find(
                            (i) =>
                                i.sectionId === 'employee' &&
                                i.id === 'reviewText',
                        )?.value || '',
                });
                reviews.push(agencyReview);
                reviews.push(profileReview);
            } else {
                const userReview = new MReview({
                    authorId: userData.documentId,
                    targetCollection: ECollections.users,
                    targetId: contract.employeeId,
                    contractId: contract.documentId,
                    ratings: inputs
                        ?.filter((i) => i.sectionId === 'employee')
                        .map((v) => ({
                            id: v.id,
                            value: v.value,
                            labelKey: v.labelKey,
                        })),
                    text: '',
                });
                reviews.push(userReview);
            }
            await callFunction('submitReview', { reviews });
        },
        [userData, contract],
    );
    /**
     * review callback to open dialog
     */
    const openReview = useCallback(async () => {
        if (!isEmployer(userData)) {
            return await dialog({
                icon: 'review',
                title: contractMessages.reviewEmployer,
                message: contractMessages.reviewEmployerInfo,
                infoBox: { message: contractMessages.reviewInfo },
                reviewSections: [
                    {
                        id: 'employer',
                        generalTitle: contractMessages.general,
                        values: [
                            {
                                id: 'taskManagement',
                                label: contractMessages.taskManagement,
                            },
                            {
                                id: 'hospitality',
                                label: contractMessages.hospitality,
                            },
                            {
                                id: 'communication',
                                label: contractMessages.communication,
                            },
                        ],
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.confirm,
                        onPress: handleReview,
                        disabled: (i) => !i || !i.length,
                    },
                ],
            });
        } else {
            return await dialog({
                icon: 'review',
                title: contractMessages.reviewEmployee,
                message: contractMessages.reviewEmployeeInfo,
                reviewSections: [
                    ...(contract?.agencyId && contract.profileId
                        ? [
                              {
                                  id: 'agency',
                                  title: contractMessages.agency,
                                  generalTitle: contractMessages.general,
                                  values: [
                                      {
                                          id: 'communication',
                                          label: contractMessages.communication,
                                      },
                                  ],
                              },
                          ]
                        : []),
                    {
                        id: 'employee',
                        title: contractMessages.employee,
                        generalTitle: contractMessages.general,
                        values: [
                            {
                                id: 'punctuality',
                                label: contractMessages.punctuality,
                            },
                            {
                                id: 'reliability',
                                label: contractMessages.reliability,
                            },
                            {
                                id: 'professionality',
                                label: contractMessages.professionality,
                            },
                            {
                                id: 'competence',
                                label: contractMessages.competence,
                            },
                            {
                                id: 'sociability',
                                label: contractMessages.sociability,
                            },
                        ],
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.confirm,
                        onPress: handleReview,
                        disabled: (i) => {
                            console.log(i);
                            return (
                                !i ||
                                !i.length ||
                                !!(
                                    contract?.agencyId &&
                                    contract.profileId &&
                                    i.length !== 8
                                ) // ! check if all sections are filled
                            );
                        },
                    },
                ],
            });
        }
    }, [userData, contract, handleReview]);
    /**
     * callback to review and complete contract afterwards
     */
    const reviewAndComplete = useCallback(async () => {
        if (!contract) return;
        // open review dialog
        if (!(await openReview())) return;
        const unlock = lock();
        const res = await callFunction('completeContract', {
            contractId: contract.documentId,
        });
        unlock();
        await dialog({
            message: contractMessages.completedContractMessage,
            title: contractMessages.completedContract,
            icon: 'success',
        });
        navigate('/contracthistory');
    }, [openReview, contract]);
    /**
     * pre file callback to determin filetype pre selections
     */
    const preFile = usePreContractFile(contract?.documentId, setReload);
    /**
     * handle file upload
     */
    const handleFile = usePostContractFile(contract, setReload);
    /**
     * callback to handle signing employer change
     */
    const handleSigningEmployerChange = useCallback(
        (signingEmployerId: string) => {
            if (!contract) {
                return;
            }
            const unlock = lock();
            callFunction('setSigningWorkplaceUser', {
                signingEmployerId,
                contractId: contract.documentId,
            }).then(() => {
                getDataById(ECollections.contracts, contract.documentId).then(
                    (r) => {
                        const c = new MContract(r);
                        setContract(c);
                        unlock();
                    },
                );
            });
        },
        [contract],
    );
    /**
     * callback to join chat
     */
    const joinChat = useCallback(() => {
        if (!contract) {
            return;
        }
        callFunction('joinContractChat', {
            contractId: contract.documentId,
        }).then(() => {
            getDataById(ECollections.chats, contract.chatId).then((cr) => {
                setChat(new MChat(cr));
            });
        });
    }, [chat, contract]);
    /**
     *
     */
    /**
     * effect to load contract and the associated chat
     */
    useEffect(() => {
        if (!id) {
            return;
        }
        /**
         * get contract
         */
        getDataById(ECollections.contracts, id).then(async (r) => {
            const c = new MContract(r);
            setContract(c);
            /**
             * get chat to contract
             * * should always be set at this point
             */
            if (c.chatId) {
                getDataById(ECollections.chats, c.chatId).then((cr) => {
                    setChat(new MChat(cr));
                });
            }
        });
    }, [id, reload]);
    /**
     * effect to show initial dialogs for contract on load
     */
    useEffect(() => {
        if (!contract) return;
        /**
         * start initial dialog if popupcheck fails
         */
        AsyncStorage.getItem('initial-popup-' + contract.documentId).then(
            async (didInitialPopupsForThisContract) => {
                /**
                 * popup for employer to close the job if job still open and has not received the popup
                 */
                if (isEmployer(userData)) {
                    if (!didInitialPopupsForThisContract) {
                        if (!contract.jobId) return;
                        const job = new MJob(
                            await getDataById(
                                ECollections.jobs,
                                contract.jobId,
                            ),
                        );
                        if (
                            [EJobStatus.public, EJobStatus.private].includes(
                                job.status,
                            )
                        ) {
                            await dialog({
                                icon: 'question',
                                title: contractMessages.closeJob,
                                message: contractMessages.closeJobText,
                                buttons: [
                                    {
                                        text: contractMessages.closeJobConfirm,
                                        onPress: () => {
                                            callFunction('closeJob', {
                                                jobId: contract.jobId,
                                            });
                                        },
                                    },
                                ],
                                cancelButton: {
                                    text: contractMessages.closeJobReject,
                                },
                                verticalButtons: true,
                            });
                        }
                    }
                }
                /**
                 * popup for contract parties initial info on what to do next if this a agency contract
                 */
                if (
                    !didInitialPopupsForThisContract &&
                    contract.agencyId &&
                    (isEmployer(userData) || isAgencyUser(userData))
                ) {
                    await dialog({
                        icon: 'info',
                        title: contractMessages.initialInfo,
                        message: isEmployer(userData)
                            ? contractMessages.initialEmployerInfoText
                            : contractMessages.initialAgencyInfoText,
                    });
                }
                /**
                 * set dialog called to true regardless of outcome
                 */
                AsyncStorage.setItem(
                    `initial-popup-${contract.documentId}`,
                    'true',
                );
            },
        );
    }, [contract]);
    /**
     * effect to application for applicant && job details
     */
    useEffect(() => {
        if (!contract || !contract.applicationId) return;
        getDataById(ECollections.applications, contract.applicationId).then(
            (v) => {
                const next = new MApplication(v);
                setApplication(next);
                setJob(next.job);
            },
        );
    }, [contract]);
    /**
     * effect to load request if request present
     */
    useEffect(() => {
        if (!contract || !contract.requestId) return;
        getDataById(ECollections.requests, contract.requestId).then((res) => {
            const next = new MRequest(res);
            setRequest(next);
        });
    }, [contract]);
    /**
     * effect to load timesheet and set completion
     */
    useEffect(() => {
        const timesheetCollection = `${ECollections.contracts}/${id}/${ECollections.timesheets}`;

        getDataIndex(timesheetCollection).then((ts) => {
            const results = (ts as any[]).map((t) => new MTimesheet(t));

            if (results.length) {
                const next = new MTimesheet(results[0]);
                if (next.complete) {
                    setTimesheetComplete(true);
                }
            }
        });
    }, [id]);
    /**
     * effect to throw premature contracts back to negotiation
     * - // ! exception for basic contracts
     */
    useEffect(() => {
        if (
            contract &&
            contract.type !== EApplicationType.default &&
            contract.status !== EContractStatus.signed
        ) {
            navigate('/contract/negotiation/' + contract.documentId);
        }
    }, [contract]);
    /**
     * effect to reply to extension
     */
    useEffect(() => {
        if (!contract) return;
        if (
            !contract.tos.length ||
            contract.tos[contract.tos.length - 1].approved
        )
            return;
        if (contract.tos[contract.tos.length - 1].from === userData.documentId)
            return;
        let approve = false;
        dialog({
            icon: 'rocketman',
            title: contractMessages.extensionRequested,
            message: format(contractMessages.extensionRequestedText, {
                to: new Date(
                    contract.tos[contract.tos.length - 1].to,
                ).toLocaleDateString('de'),
            }),
            buttons: [
                { text: actionMessages.reject, color: theme.errorColor },
                {
                    text: actionMessages.accept,
                    onPress: () => (approve = true),
                },
            ],
            cancelButton: { text: actionMessages.close },
        }).then(async (ok) => {
            if (ok) {
                const unlock = await lock();
                try {
                    await callFunction('confirmContractExtension', {
                        contractId: contract.documentId,
                        approve,
                    });
                    setReload(Date.now());
                    unlock();
                } catch (e) {
                    unlock();
                }
            }
        });
    }, [contract]);
    /**
     * return values for render
     */
    return {
        contract,
        job,
        application,
        request,
        setContract,
        chat,
        reload,
        handleSigningEmployerChange,
        preFile,
        openReview,
        reviewAndComplete,
        handleFile,
        setReload,
        joinChat,
        timesheetComplete,
        hpw,
    };
};
