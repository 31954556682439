import { Unsubscribe } from 'firebase/auth';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { View } from 'react-native';
import { ECollections } from '../../../enums';
import { MNotification } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useFormat } from '../../../utilities/intl';
import { PushNotificationContext } from '../../../utilities/pushNotifications';
import { useLocation } from '../../../utilities/routing';
import { useTheme } from '../../../utilities/styles';
import { makeMenuStyles } from '../menu.style';
import { IMenuButtonProps, MenuButton } from './MenuButton';
import { unflattenObject } from '../../../utilities/functions';
import { getRegionUrl } from '../../../utilities/constants';
import { useEnvironment } from '../../../utilities/contexts';
/**
 * Self conscious notification menu button who watches for new unread messages
 * @param param0
 * @returns
 */
export const NotificationMenuButton: FC<IMenuButtonProps> = ({
    closeSidebar,
    detail,
    icon,
    title,
    customFunction,
    targetLocation,
    exact,
}) => {
    // gloabl
    const { theme } = useTheme();
    const currentLocation = useLocation();
    const { watchDataIndex, userData, userWorkplaces, userAgencies } =
        useFireBase();
    const { sendNotification } = useContext(PushNotificationContext);
    const format = useFormat();
    const { region, environment } = useEnvironment();
    // local
    const localStyles = useMemo(() => makeMenuStyles(theme), [theme]);
    const [unsubscribe, setUnsubscribe] = useState<Unsubscribe>();
    const [hasUpdatesFrom, setHasUpdates] = useState(0);
    const [hasSeenUpdatesFrom, setHasSeenUpdates] = useState(0);
    /**
     * has updates
     */
    const hasUpdates = useMemo(
        () => hasSeenUpdatesFrom < hasUpdatesFrom,
        [hasUpdatesFrom, hasSeenUpdatesFrom],
    );
    /**
     * check if button is active and remove hasUpdates in case of acitve
     */
    const active = useMemo(() => {
        const isActive = exact
            ? targetLocation === currentLocation.pathname
            : targetLocation &&
              currentLocation.pathname.includes(targetLocation);
        if (isActive && hasUpdates) {
            setHasSeenUpdates(hasUpdatesFrom);
        }
        return isActive;
    }, [exact, currentLocation, hasUpdates]);
    /**
     * callback to handle events from get dataIndex effect
     */
    const handleNewNotificationEvent = useCallback(
        (data: any) => {
            const note = new MNotification(data);
            if (!note.readBy.find((r) => r.uid === userData.documentId)) {
                setHasUpdates(note.createdOn);
                if (hasSeenUpdatesFrom < note.createdOn) {
                    sendNotification(
                        note.titleKey
                            ? format(
                                  {
                                      id: note.titleKey,
                                  },
                                  note.variables,
                              )
                            : note.title,
                        note.messageKey
                            ? format(
                                  {
                                      id: note.messageKey,
                                  },
                                  note.variables,
                              )
                            : note.message,
                        note.url || note.linkTo
                            ? `${getRegionUrl(region, environment)}/${
                                  note.linkTo
                              }`
                            : `${getRegionUrl(
                                  region,
                                  environment,
                              )}/notification`,
                        !active,
                    );
                }
            }
        },
        [active, hasSeenUpdatesFrom],
    );
    useEffect(() => {
        const fors = ['null'];
        fors.push(`user_${userData.documentId}`);
        fors.push(`group_${userData.type}`);
        userAgencies.forEach((a) => fors.push(`a_${a.documentId}`));
        userWorkplaces.forEach((w) => fors.push(`wp_${w.documentId}`));
        const queryOptions = {
            orderBy: 'createdOn',
            filter: [
                {
                    field: 'for',
                    value: fors,
                    operator: 'in' as const,
                },
            ],
            limit: 1,
        };
        watchDataIndex(ECollections.notifications, queryOptions, (event) => {
            if (event.type === 'added') {
                handleNewNotificationEvent(unflattenObject(event.doc.data()));
            }
        }).then((us) => setUnsubscribe(() => us));
    }, [userData, handleNewNotificationEvent, userAgencies, userWorkplaces]);
    /**
     * effect to register unsubscribe as cleanup function
     * it gets triggered if react thinks it is time to clean up
     */
    useEffect(() => {
        return unsubscribe;
    }, [unsubscribe]);
    /**
     * render (could have used menu button component but it did cause warnings)
     */
    return (
        <View>
            <MenuButton
                closeSidebar={closeSidebar}
                detail={detail}
                icon={icon}
                title={title}
                customFunction={customFunction}
                targetLocation={targetLocation}
                exact={exact}
            />
            {hasUpdates && (
                <View
                    {...{ dataSet: { cy: 'new-notification-menu-bubble' } }}
                    style={localStyles.notificationBubble}
                />
            )}
        </View>
    );
};
