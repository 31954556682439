export enum ECollections {
    agencies = 'agencies',
    publicAgencies = 'publicAgencies',
    agencyNotes = 'agencyNotes',
    users = 'users',
    publicUsers = 'publicUsers',
    userNotes = 'userNotes',
    availabilities = 'availabilities',
    jobs = 'jobs',
    jobArchive = 'jobArchive',
    jobPins = 'jobPins',
    applications = 'applications',
    workplaces = 'workplaces',
    publicWorkplaces = 'publicWorkplaces',
    workplaceNotes = 'workplaceNotes',
    contracts = 'contracts',
    notifications = 'notifications',
    helps = 'helps',
    referralCodes = 'referralCodes',
    referrals = 'referrals',
    chatMessages = 'chatMessages',
    chats = 'chats',
    autoIncrements = 'autoIncrements',
    profProfiles = 'profProfiles',
    publicProfProfiles = 'publicProfProfiles',
    profProfileNotes = 'profProfileNotes',
    supportRequests = 'supportRequests',
    invites = 'invites',
    files = 'files',
    privateFiles = 'privateFiles',
    negotiationHistory = 'negotiationHistory',
    identDocuments = 'identDocuments',
    timesheets = 'timesheets',
    shifts = 'shifts',
    reviews = 'reviews',
    requests = 'requests',
    shiftPlans = 'shiftPlans',
}
