import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CText, Spinner } from '../../../../components';
import { ExpandableCard } from '../../../../components/ExpandableCard';
import PDFViewer from '../../../../components/PDFViewer';
import { EEnvironment } from '../../../../enums';
import { useEnvironment } from '../../../../utilities/contexts';
import { useFireBase } from '../../../../utilities/firebase';
import { LanguageContext, useFormat } from '../../../../utilities/intl';
import { generalMessages } from '../../../../utilities/messages';
import { profProfileMessages } from '../../ProfProfile.messages';
import { ProfileContext } from '../../../Profile/Profile.context';
/**
 * ProfProfileEdit
 * used by agencies and admins to view & edit profiles
 *
 * ! Opens user context with excessive values to prevent new context type
 * @returns
 */
export const ProfessionalResume: FC = () => {
    // global state
    const { environment } = useEnvironment();
    const format = useFormat();
    const { callFunction } = useFireBase();
    const { language } = useContext(LanguageContext);
    // local state
    const [resumeFilename, setFilename] = useState<{
        fn: string;
        issued: number;
    }>({ fn: '', issued: Date.now() });
    const { prevData } = useContext(ProfileContext);
    const [open, setOpen] = useState(false);
    /**
     * callback to init load and open the card
     */
    const loadAndOpen = useCallback(() => {
        if (!prevData.documentId) return;
        setOpen(!open);
        if (!open && !resumeFilename.fn) {
            console.log('loading resume', prevData, language);
            callFunction('getResume', {
                profProfileId: prevData.documentId,
                environment,
                lang: language,
            })
                .then((nextFN) =>
                    setFilename({ fn: nextFN, issued: Date.now() }),
                )
                .catch((e) => console.error(e));
        }
    }, [language, open, prevData]);
    /**
     * render
     */
    return (
        <ExpandableCard
            head={
                <CText
                    bold
                    message={format(profProfileMessages.previewResume)}
                />
            }
            body={
                <View style={{ marginHorizontal: 'auto' }}>
                    {resumeFilename.fn ? (
                        <PDFViewer filename={resumeFilename.fn} />
                    ) : (
                        <View>
                            <Spinner />
                            <CText centered>
                                {format(generalMessages.loadingResume)}
                            </CText>
                        </View>
                    )}
                </View>
            }
            preventHover
            customExpand={open}
            customOnClick={loadAndOpen}
        />
    );
};
