import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { ECollections, EContractStatus, EUserType } from '../../../enums';
import { MContract } from '../../../models';
import { useNavigate } from '../../../utilities/routing';
import { CText, CImage, Spinner, CButton } from '../../../components';
import { isSuperUser } from '../../../utilities/auth';
import { ScrollProvider } from '../../../utilities/contexts';
import { useFireBase } from '../../../utilities/firebase';
import { IFilter } from '../../../utilities/firebase/store';
import { useFormat } from '../../../utilities/intl';
import { generalMessages } from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { contractMessages } from '../contract.messages';
import { ContractHistoryRow } from './components/ContractHistoryRow';

export const ContractHistory: FC = () => {
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { getDataIndex, userData, userWorkplaces } = useFireBase();
    const [contracts, setContracts] = useState<MContract[]>([]);
    const [loadingContracts, setLoadingContracts] = useState(true);
    /**
     * effect to load contracts
     */
    useEffect(() => {
        const params = {
            filter: [
                { field: 'status', value: EContractStatus.completed },
            ] as IFilter[],
        };
        if (!isSuperUser(userData)) {
            if (userData.type === EUserType.employer) {
                if (!userWorkplaces.length) {
                    return;
                }
                params.filter.push({
                    field: 'workplaceUsers',
                    value: userData.documentId,
                    operator: 'array-contains',
                });
            } else if (
                userData.type === EUserType.talent &&
                userData.talentId
            ) {
                params.filter.push({
                    field: 'profileId',
                    value: userData.talentId,
                });
            } else {
                params.filter.push({
                    field: 'employeeId',
                    value: userData.documentId,
                    operator: '==',
                });
            }
        }
        getDataIndex(ECollections.contracts, params).then((res) => {
            const nextContracts = (res as MContract[]).map(
                (n) => new MContract(n),
            );
            setContracts(nextContracts);
            setLoadingContracts(false);
        });
    }, [userData, userWorkplaces]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={style.card}>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CText style={{ marginLeft: 5 }} bold headline>
                        {format(generalMessages.contractHistory)}
                    </CText>
                </View>
                <View style={style.horizontal}>
                    {![EUserType.basic, EUserType.talent].includes(
                        userData.type,
                    ) && (
                        <CButton
                            title={generalMessages.negotiations}
                            onPress={() => {
                                navigate('/negotiations');
                            }}
                            small
                            minor
                            style={style.smallMargin}
                        />
                    )}
                    <CButton
                        title={generalMessages.contracts}
                        onPress={() => {
                            navigate('/contracts');
                        }}
                        small
                        minor
                        style={style.smallMargin}
                    />
                    <View style={style.verticalPaddedThinSeparator} />
                    <CButton
                        title={contractMessages.history}
                        onPress={() => {
                            // nothingToDo
                        }}
                        small
                        style={style.smallMargin}
                    />
                </View>
            </View>
            {!loadingContracts ? (
                <>
                    {contracts.map((contract) => (
                        <ContractHistoryRow
                            key={contract.documentId}
                            contract={contract}
                        />
                    ))}
                    {!contracts.length && (
                        <View style={style.card}>
                            <CImage image="contracts" />
                            <CText centered headline>
                                {format(contractMessages.historyEmpty)}
                            </CText>
                        </View>
                    )}
                </>
            ) : (
                <Spinner />
            )}
        </ScrollProvider>
    );
};
