import { StyleSheet } from 'react-native';
import { ITheme } from './ITheme';

export const makeGlobalStyles = (theme: ITheme) =>
    StyleSheet.create({
        importantValue: {
            fontSize: 64,
            fontFamily: theme.mainFontFamily,
            fontWeight: '600',
            color: theme.accentColor,
        },
        input: {
            padding: 10,
            marginVertical: 5,
            borderStyle: 'solid',
            backgroundColor: theme.backgroundMainColor,
            color: theme.textMainColor,
            borderRadius: 10,
            minHeight: 40,
            width: 200,
            shadowColor: theme.shadowColor,
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.2,
            shadowRadius: 3.8,
            elevation: 5,
        },
        outsideContainer: {
            backgroundColor: theme.backgroundSecondaryColor,
        },
        insideContainer: {
            width: '100%',
            maxWidth: 720,
            marginHorizontal: 'auto',
            marginVertical: 20,
            backgroundColor: theme.backgroundMainColor,
            padding: 10,
            borderColor: theme.borderColor,
            borderWidth: 1,
            borderRadius: 10,
            shadowColor: theme.shadowColor,
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,
            elevation: 1,
        },
        insideContainerNoBG: {
            width: '100%',
            maxWidth: 560,
            marginHorizontal: 'auto',
            marginVertical: 20,
            padding: 10,
        },
        paddedMainView: {
            padding: 20,
            maxWidth: 1080,
            flex: 1,
            marginHorizontal: 'auto',
        },
        paddedScrollableMainView: {
            padding: 20,
            maxWidth: 1080,
            width: '100%',
            marginHorizontal: 'auto',
        },
        card: {
            backgroundColor: theme.backgroundMainColor,
            padding: 10,
            margin: 10,
            borderRadius: 10,
            shadowColor: theme.shadowColor,
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,
            elevation: 1,
        },
        embeddedCard: {
            padding: 10,
            margin: 10,
            borderRadius: 10,
        },
        invertedCard: {
            backgroundColor: theme.backgroundSecondaryColor,
        },
        headlineCard: {
            backgroundColor: theme.backgroundMainColor,
            padding: 10,
            margin: 10,
            borderRadius: 10,
            shadowColor: theme.shadowColor,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,
            elevation: 1,
            minWidth: 300,
        },
        horizontal: {
            display: 'flex',
            flexDirection: 'row',
        },
        horizontalWrap: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        flex1: {
            flex: 1,
        },
        smallMargin: {
            margin: 5,
        },
        horizontalSpaced: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignContent: 'center',
        },
        horizontalSplit: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
        },
        centeredContent: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
        },
        horizontalLayout: {
            flexDirection: 'row',
        },
        verticalLayout: {
            flexDirection: 'column',
        },
        horizontalGroup: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        horizontalReversed: {
            flexDirection: 'row-reverse',
        },
        horizontalPadded: {
            marginHorizontal: 5,
        },
        horizontalHeavyPadded: {
            marginHorizontal: 10,
        },
        verticalPadded: {
            paddingVertical: 10,
        },
        verticalHeavyPadded: {
            paddingVertical: 15,
        },
        leftPadded: {
            marginLeft: 5,
        },
        successBorder: {
            borderWidth: 1,
            borderColor: theme.successColor,
            borderStyle: 'solid',
        },
        error: {
            color: theme.errorColor,
        },
        errorBorder: {
            borderWidth: 1,
            borderColor: theme.errorColor,
            borderStyle: 'solid',
        },
        warning: {
            color: theme.warningColor,
        },
        warningBorder: {
            borderWidth: 1,
            borderColor: theme.warningColor,
            borderStyle: 'solid',
        },
        disabled: {
            backgroundColor: theme.backgroundTertiaryColor,
            borderColor: theme.borderColor,
        },
        thinSeparator: {
            height: StyleSheet.hairlineWidth,
            backgroundColor: theme.backgroundTertiaryColor,
        },
        paddedThinSeparator: {
            height: StyleSheet.hairlineWidth,
            backgroundColor: theme.backgroundTertiaryColor,
            marginVertical: 10,
        },
        verticalThinSeparator: {
            width: StyleSheet.hairlineWidth,
            backgroundColor: theme.backgroundTertiaryColor,
        },
        verticalPaddedThinSeparator: {
            width: StyleSheet.hairlineWidth,
            backgroundColor: theme.backgroundTertiaryColor,
            marginHorizontal: 10,
        },
        centeredItems: {
            alignItems: 'center',
        },
        accent: {
            color: theme.accentColor,
        },
        accentBorder: {
            borderColor: theme.accentColor,
            borderWidth: 1,
            borderStyle: 'solid',
        },
        listImage: {
            height: 50,
            width: 50,
            borderRadius: 25,
            marginRight: 10,
        },
        bigImage: {
            height: 100,
            width: 100,
            borderRadius: 50,
        },
    });
