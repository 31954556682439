import { defineMessages } from 'react-intl';

const scope = 'con.timesheet.';

export const timesheetMessages = defineMessages({
    from: {
        id: scope + 'from',
        defaultMessage: 'From',
    },
    to: {
        id: scope + 'to',
        defaultMessage: 'To',
    },
    breaks: {
        id: scope + 'breaks',
        defaultMessage: 'Breaks',
    },
    date: {
        id: scope + 'date',
        defaultMessage: 'Date',
    },
    addShift: {
        id: scope + 'addShift',
        defaultMessage: 'Add shift',
    },
    saveAndComplete: {
        id: scope + 'saveAndComplete',
        defaultMessage: 'Save and complete',
    },
    confirmComplete: {
        id: scope + 'confirmComplete',
        defaultMessage: 'Please confirm completion',
    },
    confirmCompleteText: {
        id: scope + 'confirmCompleteText',
        defaultMessage: 'You confirm, that all times are correct.',
    },
    timesheet: {
        id: scope + 'timesheet',
        defaultMessage: 'Timesheet',
    },
    timesheetList: {
        id: scope + 'timesheetList',
        defaultMessage: 'Timesheets',
    },
    noBreaks: {
        id: scope + 'noBreaks',
        defaultMessage: 'No breaks for this period',
    },
    addBreak: {
        id: scope + 'addBreak',
        defaultMessage: 'Add break',
    },
    createTimesheetDocument: {
        id: scope + 'createTimesheetDocument',
        defaultMessage: 'Create pdf',
    },
    secondarySigneeMail: {
        id: scope + 'secondarySigneeMail',
        defaultMessage: 'Secondary signee mail',
    },
    secondarySigneeMailTitle: {
        id: scope + 'secondarySigneeMailTitle',
        defaultMessage: 'Input a secondary signee mail',
    },
    secondarySigneeMailText: {
        id: scope + 'secondarySigneeMailText',
        defaultMessage:
            'Input a secondary signee mail, to expose the signature of this document to the validating authority.',
    },
    sendMail: {
        id: scope + 'sendMail',
        defaultMessage: 'Send mail',
    },
    showQrCode: {
        id: scope + 'showQrCode',
        defaultMessage: 'Show qr code',
    },
    skipSecondary: {
        id: scope + 'skipSecondary',
        defaultMessage: 'Skip',
    },
    expose: {
        id: scope + 'expose',
        defaultMessage: 'Expose timesheet for employee',
    },
    enterEmployeeMailDesc: {
        id: scope + 'enterEmployeeMailDesc',
        defaultMessage:
            'The employee will receive a mail containing the link to edit and sign the timesheet',
    },
    enterEmployeeMail: {
        id: scope + 'enterEmployeeMail',
        defaultMessage: 'Enter mail address of employee',
    },
    deleteRow: {
        id: scope + 'deleteRow',
        defaultMessage: 'Delete row',
    },
    interval: {
        id: scope + 'interval',
        defaultMessage: 'Period',
    },
    intervalMonthly: {
        id: scope + 'intervalMonthly',
        defaultMessage: 'Monthly',
    },
    intervalWeekly: {
        id: scope + 'intervalWeekly',
        defaultMessage: 'Weekly',
    },
    intervalUndefined: {
        id: scope + 'intervalUndefined',
        defaultMessage: 'Unspecified',
    },
    createSignedDocument: {
        id: scope + 'createSignedDocument',
        defaultMessage: 'Save and sign',
    },
    note: {
        id: scope + 'note',
        defaultMessage: 'Note',
    },
});
