import { defineMessages } from 'react-intl';

const scope = 'con.review.';

export const reviewMessages = defineMessages({
    recentAverage: {
        id: scope + 'recentAverage',
        defaultMessage: 'Recent average rating:',
    },
    overallAverage: {
        id: scope + 'overallAverage',
        defaultMessage: 'Overall average rating:',
    },
    reviewCount: {
        id: scope + 'reviewCount',
        defaultMessage: '{resource} has received {x} reviews.',
    },
    noReviews: {
        id: scope + 'noReviews',
        defaultMessage: '{resource} was not yet reviewed',
    },
    profile: {
        id: scope + 'profile',
        defaultMessage: 'Talentprofile',
    },
    noReviewText: {
        id: scope + 'noReviewText',
        defaultMessage: 'Reviewer did not leave a message.',
    },
    freeFormTextReview: {
        id: scope + 'freeFormTextReview',
        defaultMessage: 'Anything to add? Feel free to explain.',
    },
});
