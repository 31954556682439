import React, { FC } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { AppRouting } from './App.routing';
import { EnvironmentProvider, JoyrideProvider } from './utilities/contexts';
import { CModalProvider } from './utilities/contexts/CModal';
import { ProfileCompletionProvider } from './utilities/contexts/ProfileCompletion';
import { FireBaseProvider } from './utilities/firebase/FireBaseProvider';
import { CustomIntlProvider } from './utilities/intl';
import { PushNotificationProvider } from './utilities/pushNotifications';
import Router from './utilities/routing';
import { SecureNavigateProvider } from './utilities/routing/SecureNavigateProvider';
import { StyleProvider } from './utilities/styles/StyleProvider';

const App: FC = () => {
    return (
        <Router>
            <SecureNavigateProvider>
                <EnvironmentProvider>
                    <StyleProvider>
                        <SafeAreaProvider>
                            <CustomIntlProvider>
                                <CModalProvider>
                                    <FireBaseProvider>
                                        <ProfileCompletionProvider>
                                            <PushNotificationProvider>
                                                <JoyrideProvider>
                                                    <AppRouting />
                                                </JoyrideProvider>
                                            </PushNotificationProvider>
                                        </ProfileCompletionProvider>
                                    </FireBaseProvider>
                                </CModalProvider>
                            </CustomIntlProvider>
                        </SafeAreaProvider>
                    </StyleProvider>
                </EnvironmentProvider>
            </SecureNavigateProvider>
        </Router>
    );
};

export default App;
