import React, { FC, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    CText,
    RichTextView,
    Spinner,
    StarDisplay,
    TouchableView,
} from '../../../../../../components';
import { MJob, MWorkplace } from '../../../../../../models';
import { useFormat } from '../../../../../../utilities/intl';
import {
    dayMessages,
    doctorPositionMessages,
    educationMessages,
    fieldMessages,
    generalMessages,
    specializationMessages,
} from '../../../../../../utilities/messages';
import { useStyle } from '../../../../../../utilities/styles';
import { jobMessages } from '../../../../job.messages';
import { JobTable } from '../../../../components/JobTable';
import { ECollections, EProfession } from '../../../../../../enums';
import { useFireBase } from '../../../../../../utilities/firebase';
import { useNavigate } from '../../../../../../utilities/routing';
import { timeString } from '../../../../../../utilities/functions';

export const JobRowBody: FC<{ job: MJob }> = ({ job }) => {
    const format = useFormat();
    const { getDataById } = useFireBase();
    const style = useStyle();
    const navigate = useNavigate();
    // local state
    const [workplace, setWorkplace] = useState<MWorkplace>();
    const strippedFreeText = useMemo(() => {
        const toReturn = job.freeText
            .replace(/(<([^>]+)>)/gi, '')
            .replace(/[\r\n]+/gm, '')
            .replace(/ /g, '');

        return toReturn;
    }, [job]);
    /**
     * complex translation of about job employee string
     */
    const aboutJobString = useMemo(() => {
        const posMessage =
            doctorPositionMessages[
                job.employeePosition as keyof typeof doctorPositionMessages
            ];
        const typeMessage = generalMessages[job.employeeProfession];
        return format(
            posMessage
                ? jobMessages.jobEmployeeEdu
                : jobMessages.jobEmployeeEduNoPos,
            {
                employeeProfession:
                    job.employeeProfession === EProfession.undefined
                        ? format(fieldMessages[job.employeeField])
                        : job.employeeProfession === EProfession.doctor &&
                          posMessage
                        ? `${format(typeMessage)} - ${format(posMessage)}`
                        : format(typeMessage),
                employeeEdu: job.employeeEducations
                    .map((education) =>
                        format(
                            educationMessages[
                                education as keyof typeof educationMessages
                            ],
                        ),
                    )
                    .join(', '),
                station: posMessage && format(posMessage),
            },
        );
    }, [job]);
    /**
     * effect to load pub workplace
     */
    useEffect(() => {
        getDataById(ECollections.publicWorkplaces, job.workPlaceId).then((v) =>
            setWorkplace(new MWorkplace(v)),
        );
    }, [job]);
    /**
     * render
     */
    return (
        <View style={{ width: '100%', overflow: 'hidden' }}>
            <CText bold headline message={jobMessages.aboutEmployee} />
            <View style={style.verticalPadded}>
                {aboutJobString && <CText message={aboutJobString} />}
                {!!job.employeeYoe && (
                    <CText>
                        {format(jobMessages.jobEmployeeYoe, {
                            yoe: job.employeeYoe,
                        })}
                    </CText>
                )}
                {!!job.employeeSpecializations.length && (
                    <CText>
                        {format(jobMessages.jobEmployeeSpecs, {
                            employeeSpecs: job.employeeSpecializations
                                .map((spec) => {
                                    const specMessage =
                                        specializationMessages[
                                            spec as keyof typeof specializationMessages
                                        ];
                                    return specMessage
                                        ? format(specMessage)
                                        : undefined;
                                })
                                .join(', '),
                        })}
                    </CText>
                )}
                {!!strippedFreeText && (
                    <View style={style.verticalPadded}>
                        <CText message={jobMessages.freeTextDisplay} />
                        <RichTextView html={job.freeText} />
                    </View>
                )}
            </View>
            <View style={style.thinSeparator} />
            <View style={style.verticalPadded} />
            <CText bold headline>
                {format(jobMessages.aboutJob)}
            </CText>
            <View style={style.verticalPadded}>
                <JobTable job={job} pure />
            </View>
            {!!job.days.length && (
                <>
                    <CText bold headline>
                        {format(jobMessages.specificWorkDays)}
                    </CText>
                    <View style={[style.verticalPadded, style.horizontalSplit]}>
                        <CText message={jobMessages.workTimeSum} />
                        <CText
                            message={`${job.days.reduce(
                                (acc, d) => (acc += d.to - d.from),
                                0,
                            )} ${format(generalMessages.hours)}`}
                        />
                    </View>
                    <View style={style.verticalPadded}>
                        {job.days
                            .sort((a, b) => a.day - b.day)
                            .map((availability) => (
                                <View
                                    key={availability.day}
                                    style={style.horizontalSplit}
                                >
                                    <CText
                                        message={
                                            dayMessages[
                                                Object.keys(dayMessages).filter(
                                                    (key) =>
                                                        !key.includes('Short'),
                                                )[
                                                    availability.day - 1
                                                ] as keyof typeof dayMessages
                                            ]
                                        }
                                    />
                                    <CText
                                        message={`${timeString(
                                            availability.from,
                                            0,
                                        )} - ${timeString(availability.to, 0)}`}
                                    />
                                </View>
                            ))}
                    </View>
                </>
            )}
            <CText bold headline>
                {format(jobMessages.aboutEmployer)}
            </CText>
            {workplace ? (
                !!workplace.reviewCount && (
                    <>
                        <CText message={jobMessages.employerRating} />
                        <TouchableView
                            style={[
                                style.horizontal,
                                style.verticalPadded,
                                style.centeredItems,
                            ]}
                            onPress={() =>
                                navigate(
                                    `/reviews/${ECollections.workplaces}/${workplace.documentId}`,
                                )
                            }
                        >
                            <StarDisplay stars={workplace.averageRating} />
                            <CText message={`(${workplace.reviewCount})`} />
                        </TouchableView>
                    </>
                )
            ) : (
                <Spinner />
            )}
            <View style={style.verticalPadded}>
                <CText>
                    {format(jobMessages.jobLocation, {
                        location: job.fullAddress
                            ? job.fullAddress.zipCode
                            : job.location,
                    })}
                </CText>
            </View>
            <CText>{format(jobMessages.jobIntorductionPositivity)}</CText>
        </View>
    );
};
