import React, { FC, useEffect, useState } from 'react';
import { useParams } from '../../../utilities/routing';
import { Timesheet } from '../../Timesheet';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { Linking, View } from 'react-native';
import QRCode from 'react-qr-code';
import { CButton, CIcon, CText, Spinner } from '../../../components';
import { urlHandlerMessages } from '../urlHandler.messages';
import { useStyle, useTheme } from '../../../utilities/styles';
import { useFireBase } from '../../../utilities/firebase';
import { ECollections } from '../../../enums';
import { MTimesheet } from '../../../models';

export const FillTimesheet: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { getDataById } = useFireBase();
    const { timesheetId, contractId } =
        useParams<{ timesheetId: string; contractId: string }>();

    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState<string>();
    /**
     * effect to check on timesheet
     */
    useEffect(() => {
        if (!contractId || !timesheetId) return;
        getDataById(
            `${ECollections.contracts}/${contractId}/${ECollections.timesheets}`,
            timesheetId,
        ).then((res) => {
            if (res) {
                const ts = new MTimesheet(res);
                if (ts.fileId) {
                    setComplete(true);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
                // TODO: error case
            }
        });
    }, [timesheetId, contractId]);
    /**
     * return spinner during load
     */
    if (loading) {
        return (
            <StandaloneWrapper>
                <Spinner />
            </StandaloneWrapper>
        );
    }
    /**
     * render
     */
    return (
        <StandaloneWrapper>
            {!complete && (
                <Timesheet
                    embedded
                    signOff
                    timesheetId={timesheetId}
                    onComplete={(url) => {
                        setComplete(true);
                        setUrl(url);
                    }}
                />
            )}
            {!!complete && (
                <View style={[style.centeredItems]}>
                    {!!url && (
                        <View
                            style={[style.verticalPadded, style.centeredItems]}
                        >
                            <QRCode value={url} size={150} />
                            <View style={style.verticalPadded}>
                                <CButton
                                    small
                                    onPress={() => Linking.openURL(url)}
                                    title={urlHandlerMessages.openUrlHere}
                                />
                            </View>
                        </View>
                    )}
                    {!url && (
                        <View style={style.verticalPadded}>
                            <CIcon
                                icon="check"
                                tint={theme.successColor}
                                size={40}
                            />
                        </View>
                    )}
                    <CText message={urlHandlerMessages.timesheetSuccess} />
                </View>
            )}
        </StandaloneWrapper>
    );
};
