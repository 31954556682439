import React, { FC, useMemo } from 'react';
import { useFormat } from '../../utilities/intl';
import {
    doctorPositionMessages,
    positionMessages,
    stationMessages,
    teacherPositionMessages,
} from '../../utilities/messages';
import { CPicker } from '../elements';
import { MessageDescriptor } from 'react-intl';
import { EProfession } from '../../enums';
import { pickerMessages } from './picker.messages';
import { View } from 'react-native';

interface IPositionPickerProps {
    onChange: (next: string) => void;
    value: string;
    titleOverride?: string;
    placeholderOverride?: string;
    cy?: string;
    validity?: string | MessageDescriptor | undefined;
    profession: EProfession;
}

export const PositionPicker: FC<IPositionPickerProps> = ({
    value,
    onChange,
    cy,
    titleOverride,
    validity,
    profession,
}) => {
    const format = useFormat();
    /**
     * select edu messages based on profession
     */
    const positions = useMemo(() => {
        switch (profession) {
            case EProfession.doctor:
                return doctorPositionMessages;
            case EProfession.nurse:
                return stationMessages;
            case EProfession.teacher:
                return teacherPositionMessages;
            case EProfession.socialWorker:
                return {};
            case EProfession.undefined:
                return {};
            default:
                return {};
        }
    }, [profession]);
    /**
     * positions filtered and sorted
     */
    const filteredPositions = useMemo(() => {
        return (Object.keys(positions) as (keyof typeof positions)[])
            .map((v) => {
                return {
                    label: format(positionMessages[v]),
                    value: v,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [positions]);
    /**
     * return a empty view if there never where any contents to pick from
     */
    if (!Object.keys(positions).length) {
        return <View />;
    }
    /**
     * render
     */
    return (
        <CPicker
            cy={cy || 'position-picker'}
            title={titleOverride || format(pickerMessages.position)}
            onChange={onChange}
            value={value || ''}
            values={filteredPositions}
            validity={validity}
            placeholder={`-${format(pickerMessages.positionPlaceholder)}-`}
        />
    );
};
