import { StyleSheet } from 'react-native';
import { ITheme } from '../../../utilities/styles/ITheme';

export const makeCheckboxStyles = (theme: ITheme) =>
    StyleSheet.create({
        check: {
            width: 24,
            height: 24,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: theme.borderColor,
            padding: 0,
            marginTop: 5,
            marginHorizontal: 0,
            marginBottom: 0,
            alignItems: 'center',
            alignContent: 'center',
            shadowColor: theme.shadowColor,
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,
            elevation: 1,
            backgroundColor: theme.backgroundMainColor,
        },
        container: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        label: {
            marginLeft: 4,
            marginTop: 8,
            flexWrap: 'wrap',
            color: theme.textMainColor,
        },
    });
